import { Signal, batch, signal, useSignal, useSignalEffect } from "@preact/signals-react";
import { getState, setupHandlers, setupLocalState } from "../../library/dataService";
import isNil from "lodash-es/isNil";
import { INGUndoRedoToolbarProps } from "../../library/NGFieldExtensions";
import IconButton from "@mui/material/IconButton";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { Box } from "@mui/material";
import { getTestId, getClassName, isSignal } from "../../library/utils";
import isEqual from "lodash-es/isEqual";
import { cloneDeep } from "lodash-es";

export default function NGUndoRedoToolbar({ config, context }: INGUndoRedoToolbarProps) {
  const local = setupLocalState(
    config,
    {
      TrackedObject: useSignal(config.TrackedObject ?? {}),
      Visible: useSignal(config.Visible ?? true),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      DisableKeyActions: useSignal(config.DisableKeyActions ?? false),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  const stack: Signal<any[]> = useSignal([]);
  const index = useSignal(-1);
  const resettingState = useSignal(false);

  // Setting up a signal effect to log changes to tracked signals
  useSignalEffect(() => {
    const trackedObject = local.TrackedObject.value;

    const isNull = Object.entries(trackedObject).every(([key, value]) => {
      return isNil(value);
    });
    if (isNull) return;

    // avoid infinite loop
    if (resettingState.peek()) {
      resettingState.value = false;
      return;
    }

    const { state } = getState(context);

    batch(() => {
      //   stack.value = stack.peek().slice(0, newIndex).concat([trackedState]);
      if (isEqual(trackedObject, stack.peek()[index.peek()])) return;
      const newIndex = index.peek() + 1;

      stack.value = stack
        .peek()
        .slice(0, newIndex)
        .concat([cloneDeep(trackedObject)]);

      index.value = newIndex;
    });
    // console.log("~~useSignalEffect", stack.peek(), index.peek(), state);
  });

  useSignalEffect(() => {
    const handleKeyDown = (event) => {
      if (local.DisableKeyActions.value) return;

      if (event.ctrlKey && event.key === "z") {
        handleUndo();
      }

      if (event.ctrlKey && event.key === "y") {
        handleRedo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const handleUndo = () => {
    // Don't allow going into negative array indexes (0 is the oldest change)
    if (index.value === -1) return;

    const trackedState = stack.value[index.value - 1];

    const { state } = getState(context);

    batch(() => {
      index.value -= 1;
      resettingState.value = true;
      Object.entries(trackedState).forEach(([key, value]) => {
        if (isSignal(state[key])) state[key].value = value;
        else state[key] = signal(value);
      });
    });

    handlers["onChange"]?.(new Event("change"), trackedState);
    console.log("~~Undo", stack.value, index.value, state);
  };

  const handleRedo = () => {
    // Don't allow going beyond the last item in the array
    if (index.value === stack.value.length - 1) return;

    const trackedState = stack.value[index.value + 1];

    const { state } = getState(context);

    batch(() => {
      index.value += 1;
      resettingState.value = true;
      Object.entries(trackedState).forEach(([key, value]) => {
        state[key].value = value;
      });
    });

    handlers["onChange"]?.(new Event("change"), trackedState);

    console.log("~~Redo", stack.value, index.value, state);
  };

  return (
    <>
      {local.Visible.value && (
        <Box
          data-testid={getTestId(config)}
          data-type={config.__typename}
          sx={local.Style.value}
          className={getClassName(local.Classes)}
        >
          <IconButton disabled={index.value === 0} onClick={handleUndo} aria-label="undo">
            <UndoIcon />
          </IconButton>
          <IconButton disabled={index.value === stack.value.length - 1} onClick={handleRedo} aria-label="redo">
            <RedoIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
}
