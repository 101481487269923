import { SyntheticEvent, useCallback, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ReadonlySignal, useComputed, useSignal } from "@preact/signals-react";
import { debounce, isArray, isEqual, isNil, isUndefined, max, min, toNumber } from "lodash-es";
import { Box, Stack } from "@mui/material";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import {
  ExcelExportModule,
  LicenseManager,
  ClientSideRowModelModule,
  AdvancedFilterModule,
  FiltersToolPanelModule,
  ColumnsToolPanelModule,
  MenuModule,
  RangeSelectionModule,
  RowGroupingModule,
  SetFilterModule,
  RichSelectModule,
  StatusBarModule,
  SparklinesModule,
  RowClickedEvent,
  ColDef,
  CsvExportModule,
  ClipboardModule,
  MasterDetailModule,
  MultiFilterModule,
  SideBarModule,
  CsvExportParams,
  ExcelExportParams,
  GridOptions,
  RowValueChangedEvent,
  ColumnMovedEvent,
  DomLayoutType,
  CellStyle,
  IDateFilterParams,
  IAggFuncParams,
  SelectionChangedEvent,
  ColumnRowGroupChangedEvent,
  ColumnPivotChangedEvent,
  ColumnPinnedEvent,
  SizeColumnsToFitGridStrategy,
  ModuleRegistry,
  ExpandOrCollapseAllEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  ColumnValueChangedEvent,
} from "ag-grid-charts-enterprise";
import NGButton from "../NGButton/NGButton";
import NGLayoutItem from "../../generators/NGLayoutItem";
import { generateUID, getTestId } from "../../library/utils";
import {
  Button,
  IActionTrigger,
  List,
  ListColumn,
  Maybe,
} from "../../../resolvers-types";
import { IButtonMethods, INGListProps, RowGroupPanelShowOptions, RuntimeContext } from "../../library/NGFieldExtensions";
import {
  GetFormatFromSite,
  getState,
  setupHandlers,
  setupLocalState,
} from "../../library/dataService";
import { GridToolbarQuickFilter } from "./QuickFilter";
import { GridToolbarExport } from "./Export";
import {
  addBlankRows,
  addColumns,
  checkboxSelection,
  CustomColDef,
  defaultExportParams,
  defaultRowHeight,
  defaultRowsPerPage,
  defautGroupColumn,
  excelStyles,
  fromColDefsToConfig,
  getBindings,
  getDataTypeFilter,
  getFormatDataType,
  getRowId,
  getRows,
  headerCheckboxSelection,
  IAnyObject
} from "./NGListUtils";
import CellRendererList from "./NGCellRendererList";
import { matchTypename } from "../../library/metadataUtils";

import "ag-grid-charts-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-charts-enterprise/styles/ag-theme-quartz.css"; // Mandatory CSS required by the Data Grid
import "./NGList.css";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064288}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{JBI_Holdings}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{JBI}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{JBI}_need_to_be_licensed___{JBI}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{29_July_2025}____[v3]_[0102]_MTc1Mzc0MzYwMDAwMA==bd659fcc281dec57d192f14cfd09e0c3"
);

ModuleRegistry.registerModules([
  AdvancedFilterModule,
  ClientSideRowModelModule,
  CsvExportModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
  SparklinesModule,
]);

type IPinned = "left" | "right";
type IOnColumnUpdate = ColumnRowGroupChangedEvent | ColumnPivotChangedEvent | ColumnMovedEvent | ColumnPinnedEvent | ColumnVisibleEvent | ColumnValueChangedEvent;

const tag = "NGList";

export default function NGList({ config, context }: INGListProps) {
  const local = setupLocalState(
    config,
    {
      Columns: useSignal([]),
      ListColumns: useSignal(config.ListColumns ?? []),
      Rows: useSignal(config.Rows ?? []),
      Loading: useSignal(config.Loading ?? false),
      QuickFilterText: useSignal(config.QuickFilterText ?? undefined),
    },
    context
  );
  const location = useLocation();

  const gridRef = useRef<AgGridReact<IAnyObject>>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const advanceFilterRef = useRef<HTMLDivElement>(null);
  const quickFilterRef = useRef<HTMLInputElement>(null);
  const exportRef = useRef<HTMLButtonElement>(null);

  function getColumns(
    config: List,
    cols: Maybe<ListColumn>[] | undefined | null,
    listCols: Maybe<ListColumn>[] | undefined | null,
    context: RuntimeContext
  ): ColDef[] {
    const MUICols: CustomColDef[] = [];

    cols = [...(cols ?? []), ...(!isArray(listCols) ? [] : listCols)];
    if (isNil(cols) || cols.length == 0) return MUICols;
    const visibleColumnCount = cols.filter((item) => item?.Visible !== false).length;
    const defaultFlexWidth = visibleColumnCount > 0 ? 1 / visibleColumnCount : 1;

    cols.forEach((col, idx) => {
      if (col == null) return;
      const format = col?.FormatName ? GetFormatFromSite(col?.FormatName ?? "") : null;
      const newCol: CustomColDef = {
        field: col.Name as string,
        valueFormatter: (p) => getFormatDataType(format, p.value, col.DataType),
        filter: col?.DisableFiltering ? false : getDataTypeFilter(col.DataType),
        editable: col.Editable as boolean,
        hide: isUndefined(col.Visible) ? false : !col.Visible,
        headerName: col.HeaderName as string,
        width: col.Width ? toNumber(col.Width) : undefined,
        suppressHeaderMenuButton: !!col?.SuppressHeaderMenu,
        cellStyle: (col?.Style as CellStyle) ?? undefined,
        cellClass: col?.Classes ?? undefined,
        colId: col.Id ?? col.Name ?? generateUID(),
        flex: col.Flex ?? defaultFlexWidth,
        cellDataType: col.DataType ?? undefined,
        rowGroup: col.RowGroup ?? undefined,
        aggFunc: col.AggFunc ?? undefined,
        defaultAggFunc: col.DataType === "number" ? "sum" : "first",
        pivot: col.Pivot ?? undefined,
        pinned: col.Pinned as IPinned,
        listCol: col,
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) {
              return 0;
            }

            // In the example application, dates are stored as mm/dd/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = dateAsString.split("/");
            const year = Number(dateParts[2]);
            const month = Number(dateParts[0]) - 1;
            const day = Number(dateParts[1]);

            const cellDate = new Date(year, month, day);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        } as IDateFilterParams,
      };

      if (!isNil(col.CellLayout) || col.DisplayAsChip) {
        newCol.cellRenderer = (params: CustomCellRendererProps) => {
          // CellLayout has a Name prop to identify data row item
          const clonedConfig = getBindings(params, context, col.CellLayout);
          if (col.DisplayAsChip) {
            clonedConfig["DisplayAsChip"] = col.DisplayAsChip
            clonedConfig["__typename"] = "Label";
            clonedConfig["ChipClasses"] = col.ChipClasses;
          }

          return <NGLayoutItem config={clonedConfig} context={context} />;
        };
      }

      if (!isNil(col.CellRendererItems) && isArray(col.CellRendererItems) && col.CellRendererItems.length) {
        newCol.cellRenderer = (params: CustomCellRendererProps) =>
          <CellRendererList
            {...params}
            context={context}
            components={col.CellRendererItems}
          />
      }

      if (idx === 0 && config.ShowCheckboxSelection) {
        newCol["checkboxSelection"] = checkboxSelection;
        newCol["headerCheckboxSelection"] = headerCheckboxSelection;
      }

      MUICols.push(newCol);
    });
    return MUICols;
  }

  const handlers = setupHandlers(config, context, location);

  function onFilterTextBoxChanged() {
    gridRef.current?.api.setGridOption("quickFilterText", quickFilterRef.current?.value);
  }

  function EditToolbar() {
    const addColumnsToSheetButton: Button = {
      __typename: "Button",
      Id: `${config.Id}_AddColumnsButton`,
      Label: "Add 10 Columns",
      StartIcon: { IconName: "Add" },
      Actions: [
        {
          Trigger: "onClick",
          preHandler: (handlerName: string, action: IActionTrigger, e: SyntheticEvent, data: object, formCtx: unknown) => {
            const newCols = addColumns(config, local.Columns.value.length ?? 0, true, 10);

            local.Columns.value = [
              ...local.Columns.value,
              ...getColumns(config, newCols, local.ListColumns.value, context),
            ];
            console.log(tag, "onClick", { handlerName, e, action }, data, formCtx);
          },
        },
      ],
    };

    return (
      <>
        <Stack direction="row" margin="1rem" ref={containerRef}>
          {(config.ShowExport || config.Toolbar?.ShowExport) && <GridToolbarExport apiRef={gridRef} ref={exportRef} />}
          {config.SpreadsheetModeOptions?.ShowAddColumnsButton && (
            <NGButton config={addColumnsToSheetButton} methods={{} as IButtonMethods} context={context} />
          )}
          {(config?.Items || config.Toolbar?.Items)?.map((button) => {
            return <NGLayoutItem key={button.Id} config={button} context={context} />;
          })}
          {config.ShowQuickFilter && (
            <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} onInput={onFilterTextBoxChanged} ref={quickFilterRef} />
          )}
        </Stack>
      </>
    );
  }

  function BottomButtons() {
    if (!config?.SpreadsheetModeOptions?.ShowAddRowsButton) return null;

    const addRowsToSheetButton: Button = {
      __typename: "Button",
      Id: `${config.Id}_AddRowsButton`,
      Label: "Add 5 Rows",
      StartIcon: { IconName: "Add" },
      Actions: [
        {
          Trigger: "onClick",
          preHandler: (handlerName: string, action: IActionTrigger, e: SyntheticEvent, data: object, formCtx: unknown) => {
            const newRows = addBlankRows(local.Rows.value, 5);
            local.Rows.value = [...local.Rows.value, ...newRows];
            console.log(tag, "onClick", { handlerName, e, action }, data, formCtx);
          },
        },
      ],
    };

    const addRowsButton = <NGButton config={addRowsToSheetButton} methods={{} as IButtonMethods} context={context} />;

    return <Box sx={{ display: "flex", justifyContent: "flex-start" }}>{addRowsButton}</Box>;
  }

  function handleOnRowClicked(params: RowClickedEvent<IAnyObject>) {
    if (!isNil(handlers["onRowClick"])) handlers["onRowClick"](null, params.data);
  }

  function onSelectionChanged(params: SelectionChangedEvent<IAnyObject>) {
    if (!isNil(handlers["onSelectionChanged"]))
      handlers["onSelectionChanged"](null, params.api.getSelectedRows());
  }

  const onRowValueChanged = useCallback(
    (params: RowValueChangedEvent) => {
      const clonedRows = [...local.Rows.value];
      clonedRows[params.rowIndex ?? 0] = params.data;
      if (!isNil(handlers["onRowUpdate"])) handlers["onRowUpdate"](null, { Rows: clonedRows });
    },
    [handlers, local.Rows.value]
  );

  const handleGridEditorEvent = useCallback((setFn: () => List | undefined) => {
    const { parentState } = getState(context);
    if (!isNil(handlers["onGridConfigurationChange"]) && matchTypename("List", parentState?.["SelectedComponent"]?.value)) {
      const Config = setFn();
      if (Config) handlers["onGridConfigurationChange"](new Event("change"), { Config });
    }
  }, [handlers, context])

  const onColumnsUpdate = useCallback(
    (event: IOnColumnUpdate) => {
      function setFn() {
        const columnsDefinitions = event?.api?.getColumnDefs();
        const ListColumns = fromColDefsToConfig(columnsDefinitions as CustomColDef[]);
        if (!isEqual(ListColumns, local.ListColumns.value)) {
          return { ...config, ListColumns };
        }
        return undefined;
      }
      handleGridEditorEvent(setFn)
    },
    [config, local.ListColumns.value, handleGridEditorEvent]
  );

  const cols: ReadonlySignal<CustomColDef[]> = useComputed(() => {
    const columns = getColumns(config, local.Columns.value, local.ListColumns.value, context);
    return columns;
  });

  const rowData: ReadonlySignal<IAnyObject[]> = useComputed(() => {
    const rows = getRows(local.Rows.value);
    return rows;
  });

  const gridOptions: GridOptions<IAnyObject> = useMemo(
    () => ({
      onColumnPivotModeChanged(event) {
        event.api.sizeColumnsToFit();
      },
      statusBar: {
        statusPanels: [
          // { statusPanel: "agTotalAndFilteredRowCountComponent", key: "totalAndFilter", align: "left" },
          { statusPanel: "agTotalRowCountComponent" },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent", align: "left" },
          { statusPanel: "agAggregationComponent", align: "right" },
        ],
      },
      rowDragManaged: true,
      rowDragMultiRow: true,
      popupParent: gridContainerRef.current,
      pivotPanelShow: "always",
      suppressColumnMoveAnimation: false,
      enableRtl: /[?&]rtl=true/.test(window.location.search),
      // enableCharts: true,
      enableRangeSelection: true,
      enableFillHandle: true,
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 50,
      suppressClearOnFillReduction: false,
      rowSelection: "multiple",
      quickFilterText: "",
      groupSelectsChildren: true,
      suppressRowClickSelection: true,
      CsvExportParams: "",
      columnTypes: {
        currencyType: {
          useValueFormatterForExport: false,
        },
      },
      editType: "fullRow",
      getBusinessKeyForNode: (node) => (node.data ? node.data.name : ""),
      initialGroupOrderComparator: ({ nodeA, nodeB }) => {
        if (!nodeA?.key || !nodeB?.key) return 0;

        if (nodeA?.key < nodeB?.key) {
          return -1;
        }
        if (nodeA?.key > nodeB?.key) {
          return 1;
        }

        return 0;
      },
      onGridReady: (event) => {
        if (document.documentElement.clientWidth <= 1024) {
          event.api.closeToolPanel();
        }
      },
      advancedFilterParent: containerRef.current,
      excelStyles: excelStyles,
    }),
    []
  );

  const onFirstDataRendered = useCallback(() => {
    if (config.EnableSideBar) {
      const colDefs = gridRef.current?.api.getColumnDefs() ?? [];
      for (const def of colDefs as unknown as ColDef[]) {
        if (def?.pivot) {
          gridRef.current?.api.setGridOption("pivotMode", true);
          break;
        }
      }
    }
    if (config.ResizeColumnsIds) {
      gridRef.current?.api.autoSizeColumns(config.ResizeColumnsIds);
    }
    gridRef.current?.api.closeToolPanel();
  }, [config]);

  const onExpandOrCollapseAll = useCallback((e: ExpandOrCollapseAllEvent) => {
    function setFn() {
      const GroupingsCollapsed = e.source === "collapseAll" ? true : false;
      return { ...config, GroupingsCollapsed };
    }
    handleGridEditorEvent(setFn);
  }, [config, handleGridEditorEvent])

  const onColumnRowGroupChanged = useCallback((e: ColumnRowGroupChangedEvent) => {
    const colIds = e.columns?.map((c) => c.getColId()) ?? [];
    e.api.autoSizeColumns(["ag-Grid-AutoColumn", ...colIds]);
    onColumnsUpdate(e)
  }, [onColumnsUpdate]);

  const onColumnResized = useCallback((e: ColumnResizedEvent) => {
    function setFn() {
      if (e.source === "autosizeColumns") {
        const ResizeColumnsIds = e.columns?.map((c) => c.getColId()) ?? [];
        const columnsDefinitions = e?.api?.getColumnDefs();
        const ListColumns = fromColDefsToConfig(columnsDefinitions as CustomColDef[]);
        return { ...config, ResizeColumnsIds, ListColumns };
      }
      return undefined;
    }
    handleGridEditorEvent(setFn);
  }, [config, handleGridEditorEvent]);

  return (
    <div data-testid={getTestId(config)} data-type={config.__typename} ref={gridContainerRef} style={{ width: "100%" }}>
      <EditToolbar />
      <div ref={advanceFilterRef} />
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{
          width: "100%",
          height:
            config.GridHeight ??
            (config.GridLayout === "autoHeight" ? "100%" : `${defaultRowHeight * defaultRowsPerPage}px`),
        }}
      >
        <AgGridReact
          domLayout={(config.GridLayout as DomLayoutType) ?? "normal"}
          sideBar={
            config.EnableSideBar
              ? {
                toolPanels: ["columns", "filters"],
                position: "right",
                defaultToolPanel: "columns",
                hiddenByDefault: false,
              }
              : undefined
          }
          getChartToolbarItems={() => ["chartDownload"]}
          defaultColDef={{
            floatingFilter: !!config.ShowFloatingFilter,
            enableRowGroup: true,
            editable: !!config.ReadOnlyEdit,
            filter: "agTextColumnFilter",
            flex: 1,
            enableValue: true,
            enablePivot: true,
            enableCellChangeFlash: true,
            initialWidth: 150,
            cellClass: config.ColumnsClasses ?? undefined,
            headerClass: config.ColumnsHeaderClasses ?? undefined
          }}
          pivotMode={!!config.PivotMode}
          ref={gridRef}
          rowData={rowData.value}
          columnDefs={cols.value}
          quickFilterText={local.QuickFilterText.value}
          loading={local.Loading.value}
          getRowId={getRowId}
          enableRangeSelection
          autoGroupColumnDef={defautGroupColumn(config)}
          rowGroupPanelShow={(config.ShowGroupingPanel as RowGroupPanelShowOptions) ?? undefined}
          rowSelection={"multiple"}
          suppressAggFuncInHeader
          groupDefaultExpanded={config.GroupingsCollapsed ? 0 : -1}
          onRowClicked={handleOnRowClicked}
          onColumnValueChanged={onColumnsUpdate}
          onSelectionChanged={onSelectionChanged}
          isRowSelectable={() => true}
          rowHeight={config.RowHeight ?? defaultRowHeight}
          paginationPageSize={config.RowsPerPage ?? defaultRowsPerPage}
          paginationAutoPageSize={config.PaginationAutoPageSize ?? false}
          paginationPageSizeSelector={
            config.ShowPageSizeSelector ? [toNumber(config.RowsPerPage) ?? 0, 20, 50, 100] : false
          }
          autoSizeStrategy={{
            type: config?.RowsAutoSizeStrategy ?? "fitCellContents",
          } as SizeColumnsToFitGridStrategy}
          pagination={!!config.ShowPagination}
          gridOptions={gridOptions as unknown as GridOptions<unknown>}
          defaultCsvExportParams={defaultExportParams as CsvExportParams}
          defaultExcelExportParams={defaultExportParams as unknown as ExcelExportParams}
          enableAdvancedFilter={!!config.ShowAdvancedFilter}
          advancedFilterParent={advanceFilterRef.current}
          onFirstDataRendered={onFirstDataRendered}
          readOnlyEdit={!!config.ReadOnlyEdit}
          onColumnRowGroupChanged={onColumnRowGroupChanged}
          onRowValueChanged={onRowValueChanged}
          onColumnResized={onColumnResized}
          onExpandOrCollapseAll={onExpandOrCollapseAll}
          onColumnPinned={onColumnsUpdate}
          onColumnMoved={debounce(onColumnsUpdate, 300)}
          onColumnPivotChanged={onColumnsUpdate}
          onColumnVisible={onColumnsUpdate}
          aggFuncs={{
            sum: (params: IAggFuncParams) => {
              let sum = 0;
              params.values.forEach((value) => {
                if (typeof value === "number" || params.colDef.cellDataType === "number") sum += toNumber(value);
              });

              return sum ?? undefined;
            },
            max: (params: IAggFuncParams) => {
              return max(params.values) ?? undefined;
            },
            min: (params: IAggFuncParams) => {
              return min(params.values) ?? undefined;
            },
          }}
        />
      </div>
      <BottomButtons></BottomButtons>
    </div>
  );
}