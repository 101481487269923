import {
  CheckboxSelectionCallbackParams,
  ColDef,
  ICellRendererParams,
  HeaderCheckboxSelectionCallbackParams,
  ExcelStyle,
  GetRowIdParams
} from "ag-grid-charts-enterprise";
import { List, ListColumn, SpreadsheetModeOptions } from "../../../resolvers-types";
import { isNil } from "lodash-es";
import { formatDate, formatNumber, generateUID } from "../../library/utils";
import { getFormData, getScope, getState } from "../../library/dataService";
import { getExprValue } from "../../library/interpreter";

export type IAnyObject = { [key: string]: string };


export interface CustomColDef extends ColDef {
  listCol?: ListColumn;
  autoSize?: boolean;
}

const defaultRowHeight = 42;
const defaultRowsPerPage = 25;

function checkboxSelection(params: CheckboxSelectionCallbackParams | ICellRendererParams) {
  if (!params.node.group) {
    return !params.node.parent?.groupData;
  }
  return false;
}

function headerCheckboxSelection(params: HeaderCheckboxSelectionCallbackParams) {
  return params.api.getRowGroupColumns().length === 0;
}

function getFormatDataType(format, value, dataType) {
  if (isNil(format)) return value;
  if (!dataType) return value;
  if (dataType === "number" && !isNil(format.NumberFormat)) return formatNumber(value, format.NumberFormat);
  if ((dataType === "date" || dataType === "dateString") && !isNil(format.DateFormat))
    return formatDate(value, format.DateFormat);
  return value;
}

function getDataTypeFilter(dataType) {
  if (!dataType) return "agTextColumnFilter";
  if (dataType === "number") return "agNumberColumnFilter";
  if (dataType === "date" || dataType === "dateString") return "agDateColumnFilter";
}

const defautGroupColumn = (config: List): ColDef => ({
  headerName: "Group",
  minWidth: 150,
  initialWidth: 150,
  field: "name",
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  cellRendererParams: {
    checkbox: checkboxSelection,
  },
  cellClass: config.GroupingClasses ?? undefined,
  headerClass: config?.GroupingHeaderClasses ?? undefined
});

function getRows(rows: { [key: string]: string }[] | undefined | null) {
  return (
    rows?.map((row) => ({
      ...row,
      Id: row?.Id ?? row?.id ?? generateUID(),
    })) ?? []
  );
}
const defaultExportParams = {
  headerRowHeight: 40,
  rowHeight: 30,
  fontSize: 14,
  addImageToCell: () => null,
}

const getRowId = ({ data }: GetRowIdParams) => {
  return data?.Id ?? data?.id;
};


function numberToLetters(n: number): string {
  let result = "";
  while (n > 0) {
    n--; // Decrement n first to convert from 1-based index to 0-based.
    const remainder = n % 26;
    const letter = String.fromCharCode(remainder + 65); // Convert to ASCII (65 is "A")
    result = letter + result;
    n = Math.floor(n / 26);
  }
  return result;
}

function addBlankRows(rows: { [key: string]: string | number }[], numberOfRows: number): IAnyObject[] {
  const newRows: IAnyObject[] = [];

  const highestId =
    isNil(rows) || rows.length == 0 ? 0 : rows.reduce((max, item) => (Number(item.Id) > max ? Number(item.Id) : Number(max)), Number(rows[0].Id));

  for (let i = 1; i <= numberOfRows; i++) {
    const newRow = {
      Id: (i + highestId).toString(),
    };
    newRows.push(newRow);
  }

  return newRows;
}

function addColumns(config: List, idStart: number, skipAddingId: boolean, numberOfColumns: number): ListColumn[] {
  // First column is always the ID column
  const columns: ListColumn[] = [];
  const options: SpreadsheetModeOptions = config?.DoNotEnforceIdColumn
    ? {
      DoNotEnforceIdColumn: config?.DoNotEnforceIdColumn,
    }
    : {};

  if (options.DoNotEnforceIdColumn !== true && !skipAddingId) {
    const idColumm = {
      __typename: "ListColumn",
      Id: `${idStart++}`,
      HeaderName: "",
      Classes: ["MuiDataGrid-columnHeader"],
      Name: "Id",
      MinWidth: 40,
      IsPrimaryKey: true,
    } as ListColumn;
    columns.push(idColumm);
  }

  for (let i = 0; i < numberOfColumns; i++) {
    const column = {
      __typename: "ListColumn",
      Id: (i + idStart).toString(),
      HeaderName: numberToLetters(i + idStart),
      Name: numberToLetters(i + idStart),
      Editable: true,
    } as ListColumn;

    columns.push(column);
  }

  return columns;
}

const excelStyles: ExcelStyle[] = [
  {
    id: "v-align",
    alignment: {
      vertical: "Center",
    },
  },
  {
    id: "alphabet",
    alignment: {
      vertical: "Center",
    },
  },
  {
    id: "good-score",
    alignment: {
      horizontal: "Center",
      vertical: "Center",
    },
    interior: {
      color: "#C6EFCE",
      pattern: "Solid",
    },
    numberFormat: {
      format: "[$$-409]#,##0",
    },
  },
  {
    id: "bad-score",
    alignment: {
      horizontal: "Center",
      vertical: "Center",
    },
    interior: {
      color: "#FFC7CE",
      pattern: "Solid",
    },
    numberFormat: {
      format: "[$$-409]#,##0",
    },
  },
  {
    id: "header",
    font: {
      color: "#44546A",
      size: 16,
    },
    interior: {
      color: "#F2F2F2",
      pattern: "Solid",
    },
    alignment: {
      horizontal: "Center",
      vertical: "Center",
    },
    borders: {
      borderTop: {
        lineStyle: "Continuous",
        weight: 0,
        color: "#8EA9DB",
      },
      borderRight: {
        lineStyle: "Continuous",
        weight: 0,
        color: "#8EA9DB",
      },
      borderBottom: {
        lineStyle: "Continuous",
        weight: 0,
        color: "#8EA9DB",
      },
      borderLeft: {
        lineStyle: "Continuous",
        weight: 0,
        color: "#8EA9DB",
      },
    },
  },
  {
    id: "currency-cell",
    alignment: {
      horizontal: "Center",
      vertical: "Center",
    },
    numberFormat: {
      format: "[$$-409]#,##0",
    },
  },
  {
    id: "boolean-type",
    dataType: "Boolean",
    alignment: {
      vertical: "Center",
    },
  },
  {
    id: "country-cell",
    alignment: {
      indent: 4,
    },
  },
];

function fromColDefsToConfig(colDefs: CustomColDef[]) {
  return colDefs?.map((col) => {
    const o = {} as ListColumn;
    for (const [k, v] of Object.entries(col)) {
      if (k === "listCol" || k === "autoSize") continue;
      o[k.charAt(0).toUpperCase() + k.slice(1)] = v
      // workarounds
      if (k === "hide") o["Visible"] = !v;
    }
    return {
      ...col.listCol,
      ...o,
    };
  }) ?? [];
}

const getBindings = (params, context, config) => {
  const { state, form, parentState } = getState(context);
  const scope = getScope(context, config, state, form, {}, parentState);
  scope["Row"] = params.data;
  const configClone = { ...config, Bindings: {}, getData: () => params.data };
  for (const [k, v] of Object.entries(config?.["Bindings"] || { Value: `Row.${params.colDef?.field}` })) {
    let srcValue: unknown = null;
    if (v == "Form") {
      srcValue = getFormData(scope.Form);
    } else if (v) {
      srcValue = getExprValue(v as string, scope, null);
    }
    configClone[k] = srcValue;
  }
  return configClone
}

export {
  defaultRowHeight,
  defaultRowsPerPage,
  headerCheckboxSelection,
  defautGroupColumn,
  checkboxSelection,
  getFormatDataType,
  getDataTypeFilter,
  excelStyles,
  getRows,
  defaultExportParams,
  getRowId,
  addBlankRows,
  addColumns,
  numberToLetters,
  fromColDefsToConfig,
  getBindings
};

