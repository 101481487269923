import { MouseEvent, useRef } from "react";
import { Document, Page } from "react-pdf";
import { batch, useSignal } from "@preact/signals-react";
import { SxProps, Theme } from "@mui/system";
import { Box, Button, Stack, Typography } from "@mui/material";
import NGIcon from "../NGIcon/NGIcon";
import { INGDocumentViewerProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, getsxObject, getClassName } from "../../library/utils";
import { isNil } from "lodash-es";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();


const tag = "NGDocumentViewer";

interface DocumentViewerSizeProps {
  height?: string;
  width?: string;
}

const defaultStyle = (width = "fit-content") => ({
  width,
  position: "relative",
  "& .react-pdf__Page": {
    width,
  },
  "& .react-pdf__Document": {
    width,
  },
});

export default function NGDocumentViewer({ config, context }: INGDocumentViewerProps) {
  const local = setupLocalState(
    config,
    {
      NumPages: useSignal(0), // Initialize with 0, updated on document load
      CurrentPage: useSignal(1), // Signal to track the current page
      PreviewMode: useSignal(config.PreviewMode ?? false),
      Scale: useSignal(config.Scale ?? 1),
      Visible: useSignal(config.Visible != false ? true : config.Visible),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Value: useSignal(config.Value ?? ""),
    },
    context
  );
  const paginationRef = useRef<HTMLDivElement>(null);
  const handlers = setupHandlers(config, context);

  function handleOnClick(event: MouseEvent<HTMLDivElement>) {
    if (!isNil(handlers["onClick"])) handlers["onClick"](new Event("click"), event);
  }

  function onDocumentLoadSuccess({ numPages }) {
    batch(() => {
      local.NumPages.value = numPages; // Set the total number of pages once the document is loaded
      local.CurrentPage.value = 1; // Reset the current page to 1
    });
  }

  function goToPrevPage() {
    if (local.CurrentPage.value > 1) {
      local.CurrentPage.value -= 1;
    }
  }

  function goToNextPage() {
    if (local.CurrentPage.value < local.NumPages.value) {
      local.CurrentPage.value += 1;
    }
  }

  return (
    local.Visible.value && (
      <Box
        data-testid={getTestId(config)}
        data-type={config.__typename}
        sx={getsxObject(local.Style.value, defaultStyle(local.Style.value?.width)) as SxProps<Theme>}
        className={getClassName(local.Classes, context)}
        onClick={handleOnClick}
      >
        <Document
          file={local.Value.value}
          onLoadSuccess={onDocumentLoadSuccess}
          className={getClassName(local.Classes, context)}
          onMouseEnter={() => paginationRef.current?.style.setProperty("opacity", "1")}
          onMouseLeave={() => paginationRef.current?.style.setProperty("opacity", "0")}
        >
          <Page
            pageNumber={local.CurrentPage.value}
            scale={local.Scale.value}
            renderAnnotationLayer={!local.PreviewMode.value}
            renderTextLayer={!local.PreviewMode.value}
            // height={getSizeAsNumber((getsxObject(local.Style.value) as DocumentViewerSizeProps).height)}
            // width={getSizeAsNumber((getsxObject(local.Style.value) as DocumentViewerSizeProps).width)}
            style={local.Style.value}
          />
          {!local.PreviewMode.value && (
            <Stack
              direction="row"
              position="absolute"
              bottom="5%"
              bgcolor="white"
              left="50%"
              sx={{
                transform: "translate(-50%)",
                transition: "opacity ease-in-out .2s",
                opacity: 0,
              }}
              boxShadow="0 30px 40px 0 rgba(16, 36, 94, .2)"
              borderRadius="4px"
              border="1px solid #E0E0E0"
              zIndex={2}
              ref={paginationRef}
            >
              <Button onClick={goToPrevPage} disabled={local.CurrentPage.value <= 1}>
                <NGIcon config={{ IconName: "ChevronLeft" }} context={context} />
              </Button>
              <Typography component="p" marginBlockStart="1rem" marginBlockEnd="1rem">
                Page {local.CurrentPage.value} of {local.NumPages.value}
              </Typography>
              <Button onClick={goToNextPage} disabled={local.CurrentPage.value >= local.NumPages.value}>
                <NGIcon config={{ IconName: "ChevronRight" }} context={context} />
              </Button>
            </Stack>
          )}
        </Document>
      </Box>
    )
  );
}
