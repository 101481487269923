import React from 'react';
import { ButtonProps } from '@mui/material/Button';
import { TooltipProps } from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { SaveAlt } from "@mui/icons-material";
import MenuList from '@mui/material/MenuList';
import { Button, Menu } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-charts-enterprise';
import { GridCsvExportProps, GridPrintExportProps } from '../../library/NGFieldExtensions';


export interface GridExportDisplayOptions {
  /**
   * If `true`, this export option will be removed from the GridToolbarExport menu.
   * @default false
   */
  disableToolbarButton?: boolean;
}

export interface GridExportMenuItemProps<Options> {
  hideMenu?: () => void;
  options?: Options & GridExportDisplayOptions;
  apiRef: React.RefObject<AgGridReact<any>>;
}

export type GridCsvExportMenuItemProps = GridExportMenuItemProps<GridCsvExportProps>;

export type GridPrintExportMenuItemProps = GridExportMenuItemProps<GridPrintExportProps>;

export interface GridToolbarExportProps {
  csvOptions?: GridCsvExportProps & GridExportDisplayOptions;
  printOptions?: GridPrintExportProps & GridExportDisplayOptions;
  /**
   * The props used for each slot inside.
   * @default {}
   */
  slotProps?: { button?: Partial<ButtonProps>; tooltip?: Partial<TooltipProps> };
  apiRef: React.RefObject<AgGridReact<any>>;
  [key: string]: any;
}



export function GridCsvExportMenuItem(props: GridCsvExportMenuItemProps) {
  const { hideMenu, options, apiRef, ...other } = props;

  return (
    <MenuItem
      onClick={() => {
        apiRef.current?.api.exportDataAsCsv(options);
        hideMenu?.();
      }}
      {...other}
    >
      Download as CSV
    </MenuItem>
  );
}



export function GridPrintExportMenuItem(props: GridPrintExportMenuItemProps) {
  const { hideMenu, options, apiRef, ...other } = props;

  function onBtPrint() {
    setPrinterFriendly(apiRef.current?.api);

    setTimeout(() => {
      print();
      setNormal(apiRef.current?.api);
    }, 500);
  }

  function setPrinterFriendly(api?: GridApi) {
    api?.setGridOption("domLayout", "print");
  }

  function setNormal(api?: GridApi) {
    api?.setGridOption("domLayout", undefined);
  }

  return (
    <MenuItem
      onClick={() => {
        onBtPrint();
        hideMenu?.();
      }}
      {...other}
    >
      Print
    </MenuItem>
  );
}

export function GridExcelExportMenuItem(props: GridPrintExportMenuItemProps) {
  const { hideMenu, options, apiRef, ...other } = props;

  return (
    <MenuItem
      onClick={() => {
        apiRef.current?.api.exportDataAsExcel(options);
        hideMenu?.();
      }}
      {...other}
    >
      Download as Excel
    </MenuItem>
  );
}

const GridToolbarExport = React.forwardRef<HTMLButtonElement, GridToolbarExportProps>(
  function GridToolbarExport(props, ref) {
    const { csvOptions = {}, printOptions = {}, excelOptions, apiRef, ...other } = props;

    return (
      <GridToolbarExportContainer {...other} ref={ref}>
        <GridCsvExportMenuItem apiRef={apiRef} />
        <GridExcelExportMenuItem apiRef={apiRef} />
        <GridPrintExportMenuItem apiRef={apiRef} />
      </GridToolbarExportContainer>
    );
  },
);



export { GridToolbarExport };




interface GridToolbarExportContainerProps {
  /**
   * The props used for each slot inside.
   * @default {}
   */
  slotProps?: { button?: Partial<ButtonProps>; tooltip?: Partial<TooltipProps> };
}

const GridToolbarExportContainer = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<GridToolbarExportContainerProps>
>(function GridToolbarExportContainer(props, ref) {
  const { children, slotProps = {} } = props;
  const buttonProps = slotProps.button || {};
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const buttonRef2 = React.useRef<HTMLButtonElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prevOpen) => !prevOpen);
    buttonProps.onClick?.(event);
  };

  const handleMenuClose = () => setOpen(false);

  if (children == null) {
    return null;
  }

  return (
    <React.Fragment>

      <Button
        ref={buttonRef}
        size="small"
        startIcon={<SaveAlt />}
        aria-expanded={open}
        aria-haspopup="menu"
        aria-controls={open ? 'export1' : undefined}
        id='export1'
        {...buttonProps}
        onClick={handleMenuOpen}
      >
        Export
      </Button>
      <Menu
        open={open}
        anchorEl={buttonRef.current}
        onClose={handleMenuClose}
      >
        <MenuList
          id='export1'
          autoFocusItem={open}
        >
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return child;
            }
            return React.cloneElement<any>(child, { hideMenu: handleMenuClose });
          })}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
});

export { GridToolbarExportContainer };
