import { getOidc, useOidc } from "./auth";
import { native } from "./native";

let checkExpiryInterval = null; // Define outside to keep track of the interval

export function logoutOnExpiry(unixExpiryTime: number, url) {
  if (checkExpiryInterval) {
    clearInterval(checkExpiryInterval);
  }

  const expTime = unixExpiryTime * 1000; // Convert to milliseconds

  checkExpiryInterval = setInterval(() => {
    const currentTime = Date.now();

    if (currentTime >= expTime) {
      document.cookie = "Api.Token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
      logoutClient();
      clearInterval(checkExpiryInterval); // Clear the interval to stop checking
    }
  }, 1000); // Check every second
}

function sendReactNativeLogoutMessage() {
  native.publish("logout", {}, null);
}

export function logoutClient() {
  // Logout on all clients
  sendReactNativeLogoutMessage();
  // Have to redirect using full page reload so the top level route gets anonymous menu
  window.location.href = `/local/login`;
}
