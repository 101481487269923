import { Location } from "react-router-dom";
import { isArray, isNil, isObject, last } from "lodash-es";
import { generateUID } from "./utils";
import { log } from "./logger";
import { controlLibrary } from "../sampleData/editor-gui/controlLibrary";
import { getBaseComponent } from "../generators/codeGeneration/generationMethods";
import { INGTreeListItems } from "./NGFieldExtensions";

export function cloneJsonWithIDs(obj: object, replacements?: { [key: string]: string }, keepers?: object): any {
  let result: any = null;
  const ids: { [key: string]: string } = {};

  try {
    result = cloneJsonNode(obj, replacements, ids);
  } catch (error) {
    console.error("Error cloning JSON node:", error);
  }

  result = replaceRemainingIds(result, ids);
  if (keepers) {
    return { ...result, ...keepers };
  }
  return result;
}

function replaceRemainingIds(component: any, ids: { [key: string]: string }): any {
  let jsonString = JSON.stringify(component);
  for (const id in ids) {
    jsonString = jsonString.replace(new RegExp(`"${id}"`, "g"), `"${ids[id]}"`);
  }

  return JSON.parse(jsonString);
}

function replaceWithCaseSensitivity(originalText: string, search: string, replace: string): string {
  if (!search || replace === null) return originalText;

  const isFirstLetterUppercase = search[0] === search[0].toUpperCase();
  const adjustedReplace = isFirstLetterUppercase ? replace[0].toUpperCase() + replace.substring(1) : replace;

  return originalText.replace(new RegExp(search, "gi"), adjustedReplace);
}

function cloneJsonNode(
  node: any,
  replacements: { [key: string]: string } | undefined,
  ids: { [key: string]: string },
  propertyName: string | null = null
): any {
  if (isObject(node) && !isArray(node)) {
    const clonedObject: { [key: string]: any } = {};
    for (const property in node) {
      if (property === "Id") {
        const oldId = node[property] as string;
        const newId = ids[oldId] || generateUID();
        ids[oldId] = newId;
        clonedObject[property] = newId;
      } else {
        clonedObject[property] = cloneJsonNode(node[property], replacements, ids, property);
      }
    }
    return clonedObject;
  } else if (isArray(node)) {
    const clonedArray: any[] = [];
    for (const element of node) {
      if (propertyName === "Items" && typeof element === "string") {
        const oldId = element as string;
        const newId = ids[oldId] || generateUID();
        ids[oldId] = newId;
        clonedArray.push(newId);
      } else {
        clonedArray.push(cloneJsonNode(element, replacements, ids, propertyName));
      }
    }
    return clonedArray;
  } else if (typeof node === "string") {
    if (replacements) {
      for (const key in replacements) {
        if (node.toLowerCase().includes(key.toLowerCase())) {
          const newValue = replaceWithCaseSensitivity(node, key, replacements[key]);
          return newValue;
        }
      }
    }
    return node;
  }
  return node;
}

export type ItemOperations = {
  canDrag: boolean;
  canReparent: boolean;
  canReorder: boolean;
  canDelete: boolean;
  canBeParent: boolean;
};

export type ItemNode = {
  Id: string;
  __typename: string | null;
  isHorizontal: boolean;
  level: number;
  parent: string;
  path: number[];
  index: number;
  pathIds: string[];
  config: any;
  operations: ItemOperations;
};

export function buildInitialDataForTree(data: any) {
  const clonedData = cloneJsonWithIDs(data.Template);
  return {
    ...buildTreeItems(clonedData)[0],
    Template: clonedData,
  };
}

export function buildMetadataMap(
  indexMap: Map<string, ItemNode>,
  node: any,
  parent: any,
  level: number,
  path: number[],
  pathIds: string[],
  key = "Items"
) {
  if (node == null) return indexMap;

  if (node.Id == null) {
    node.Id = generateUID();
  }

  const type = getTypename(node);

  const designNode: ItemNode = {
    Id: node.Id,
    __typename: type,
    level: level,
    parent: parent?.Id,
    path,
    pathIds,
    isHorizontal: node.Layout?.Direction == "row" || (node.IgnoreLayout && node.Style.flexDirection == "row"),
    index: last(path) ?? 0,
    config: node,
    operations: getItemOperations(node, parent),
  };

  indexMap.set(node.Id, designNode);

  const isComponent = type === "Component";
  const isPage = type === "Page";
  // Menu or Features always accept children
  const isMenuOrFeature = type === "Feature" || type === "Menu"
  if ((isMenuOrFeature || isPage) && !node[key]) {
    node[key] = []
  }

  if (isComponent) {
    buildMetadataMap(
      indexMap,
      node.ComponentContainer,
      node,
      level + 1,
      [...path, 0],
      [...pathIds, node.ComponentContainer.Id],
      key
    );
  } else if (Array.isArray(node[key])) {
    node[key].forEach((item, i) => {
      buildMetadataMap(indexMap, item, node, level + 1, [...path, i], [...pathIds, item.Id], key);
    });
  }
}

export function getItemOperations(node: any, parent: any): ItemOperations {
  const containersWithRequiredChild = ["Repeater", "TabContainer", "Component", "TabItem"];
  const isRequiredContainer = isContainer(node) && containersWithRequiredChild.includes(parent?.__typename);
  const isRequiredTab = node.__typename == "TabItem" && parent?.__typename == "TabContainer";
  const isTopLevelComponent = node.__typename == "Component" && (parent == null || parent.__typename == "Page");

  const ops: ItemOperations = {
    canDelete: !isRequiredContainer && !isTopLevelComponent,
    canReparent: !isRequiredContainer && !isRequiredTab && !isTopLevelComponent,
    canReorder: !isRequiredContainer && !isTopLevelComponent,
    canBeParent: containersWithRequiredChild.includes(node.__typename) || isContainer(node) || isMenuOrFeature(node) || isPage(node),
    canDrag: false,
  };

  ops.canDrag = ops.canReorder || ops.canReparent;

  return ops;
}

export function mapMetadataRec(node, key = "Items", mapFn, parent = null, level: number, idx: number, parentNode) {
  // Determine the children list based on the node type
  let children;
  if (isComponent(node)) {
    children = node.ComponentContainer ? [node.ComponentContainer] : [];
  } else {
    children = node[key] || [];
  }

  // Apply the mapping function to the current node
  const newNode = mapFn(node, parent, level, idx, parentNode);

  // Recursively apply buildTree to all children and assign to 'children' property in new node
  newNode.children = children.map((child, idx) => mapMetadataRec(child, key, mapFn, node, level + 1, idx, newNode));

  return newNode;
}

export function getItemIdentifier(item, type) {
  return formatLabel(item, item.UniqueName ?? item.Name ?? item.Label ?? item.Value ?? item.Id, type);
}

export function getItemLabel(item, type, root) {
  const label = item.UniqueName ?? item.Name ?? item.Label ?? item.Value ?? item.Id;
  let node = findParentAndIndex(root, { Id: item.Id });

  if (type === "TabItem") return formatLabel(item, label, item.Title ?? type);

  if (!isContainer(item)) return formatLabel(item, label, type);
  // handle for first component wrapper
  if (!node) {
    node = { parent: root, index: 0, item };
  }

  if (node && (node as any)?.item && isContainer((node as any)?.item) && !!(node as any)?.item.Style) {
    const isVerticalContainer =
      (node as any)?.item.Style.display == "flex" && (node as any)?.item.Style.flexDirection == "column";
    const isHorizontalContainer =
      (node as any)?.item.Style.display == "flex" && (node as any)?.item.Style.flexDirection == "row";
    const isGridContainer = (node as any)?.item.Style.display == "grid";

    if (isVerticalContainer) return "Vertical Container" + " - " + item.Id;
    if (isHorizontalContainer) return "Horizontal Container" + " - " + item.Id;
    if (isGridContainer) return "Grid Container" + " - " + item.Id;
  }

  return formatLabel(item, label, type);
}

function formatLabel(item, label, identifier) {
  if (label == null) return `${identifier} - `;
  const result = label?.length > 50 ? label.substring(0, 50) + "..." : label;
  if (!identifier) return result;
  return `${identifier} - ${result}`;
}

export function buildTreeItems(node, childrenIdentifier = "Items"): any[] {
  const key = childrenIdentifier || "Items"
  const mapFn = (item, parent, level, index, parentNode) => {
    const ctrl = getLibraryControl(item);
    const type = getTypename(item);

    if (item?.Id == null || !item?.Id) {
      item.Id = generateUID();
    }
    const label = getItemLabel(item, ctrl?.Name ?? type, parent);
    return {
      id: item.Id,
      type,
      item,
      label,
      itemType: "TreeItem",
      icon: getTreeItemIcon(item),
      level,
      parentRestrictions: getNodeParentRestrictions(item),
      childRestrictions: getNodeChildRestrictions(item),
      parent: parentNode,
      index,
      children: [],
      expanded: true,
      operations: getItemOperations(item, parent),
    };
  };
  return node == null ? [] : [mapMetadataRec(node, key, mapFn, null, 0, 0, null)];
}

export function getTreeItemIcon(node: any) {
  if (!node) return null;
  const item = getLibraryControl(node);
  if (!item && isComponent(node)) {
    return getBaseComponent("", null).Icon;
  }
  return item?.Icon;
}

function getLibraryControl(node: any) {
  const typename = getTypename(node);
  return controlLibrary.Components.find((component) =>
    node.Type ? component?.Template?.["Type"] === node.Type : component?.Template?.__typename === typename
  );
}

export function getFilteredTree(data: INGTreeListItems[], searchInput: string): any[] {
  const result = [...data];
  return result.filter((item, idx) => {
    const searchMatchLabel = ((item.label as string) ?? "").toLowerCase().includes(searchInput.toLowerCase());
    const searchMatchType = ((item.type as string) ?? "").toLowerCase().includes(searchInput.toLowerCase());
    if (item.children) {
      const newChildren = getFilteredTree(item.children, searchInput);
      const hasMathcingChildren = newChildren.length > 0;
      result[idx].children = newChildren;
      return hasMathcingChildren || searchMatchLabel || searchMatchType;
    } else return searchMatchLabel || searchMatchType;
  });
}

export function getNodeParentRestrictions(node: { __typename: string }): string | null {
  const restrictionMap = {
    TabItem: "TabContainer",
    // add more
    default: null,
  };

  return restrictionMap[node.__typename] ?? restrictionMap.default;
}
export function getNodeChildRestrictions(node: { __typename: string }): string | null {
  const restrictionMap = {
    TabContainer: "TabItem",
    // add more
    default: null,
  };

  return restrictionMap[node.__typename] ?? restrictionMap.default;
}

export function getTypename(control: any): string | null {
  if (isNil(control)) return null;
  return control.__typename ?? control.Typename ?? control.TypeName ?? null;
}

export function isLayout(c: any) {
  return getTypename(c)?.endsWith("Layout") ?? false;
}

export function findParentAndIndex(
  root: any,
  needle: object
): { parent: any | null; index: number | null; item?: any } | null {
  function search(node: any | null, parent, level: number, path: number[]) {
    if (!node) return null;
    const isComponent = getTypename(node) == "Component";
    const items = isComponent ? node.ComponentContainer.Items : node.Items;
    if (isArray(items)) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (Object.entries(needle).every(([k, v]) => item[k] == v)) {
          return {
            parent: isComponent ? node.ComponentContainer : node,
            index: i,
            level,
            path,
            item,
            itemPath: [...path, i],
          };
        }
        const result = search(item, node, level + 1, [...path, i]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  return search(root, null, 0, []);
}

export function matchTypename(match: string, config: unknown): boolean {
  if (isNil(match) || isNil(config)) return false;
  return getTypename(config)?.includes(match) ?? false;
}

export function isContainer(config): boolean {
  if (isNil(config)) return false;
  return getTypename(config)?.includes("Container") ?? false;
}

export function isMenuOrFeature(config): boolean {
  if (isNil(config)) return false;
  return isMenu(config) || isFeature(config);
}

export function isMenu(config): boolean {
  if (isNil(config)) return false;
  return (getTypename(config)?.includes("Menu")) ?? false;
}

export function isFeature(config): boolean {
  if (isNil(config)) return false;
  return (getTypename(config)?.includes("Feature")) ?? false;
}

export function isComponentGuiRoute(location?: Location<any>) {
  if (isNil(location)) return false;
  return location.pathname === "/ngeditor/component";
}

export function isComponent(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Component";
}

export function isRepeater(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Repeater";
}

export function isPage(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Page";
}

export function isReference(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Reference";
}

export function getItems(config, key = "Items") {
  return config[key] ?? config.ComponentContainer?.[key] ?? config.Content?.[0];
}

export function findParentPropEditorFormId(context) {
  for (let i = context.Path.length - 1; i >= 0; i--) {
    if (context.Path[i - 1].__typename === "PropertiesEditor" && context.Path[i].__typename === "Form")
      return context.Path[i].Id;
  }

  log.error(
    `NG${context.Path[context.Path.length - 1].__typename}`,
    "Error, could not find parent PropertiesEditor Form "
  );

  return null;
}
