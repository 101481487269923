import { Checkbox, IconButton, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { Handle, Position, useReactFlow } from "@xyflow/react";
import { actions, Handles, NextActionType } from "../../library/actionEditor";
import { Delete } from "@mui/icons-material";
import NGPropertiesEditor from "../../generators/NGPropertiesEditor";
import { batch, Signal, useSignal } from "@preact/signals-react";
import ActionDialog from "./ActionDialog";
import NGMultiSelect from "../NGMultiSelect/NGMultiSelect";
import { debounce, isEqual, merge } from "lodash-es";
import { memo } from "react";

const fieldsToObject = (fields) => {
  const nonBindingsFields = fields?.reduce(function (field, x) {
    field[x.Name] = x.Value;
    return field ?? {};
  }, {});
  const bindingsFields = fields?.reduce(function (field, x) {
    if (x.Bindings) {
      field[`Bindings.${x.Name}`] = x.Bindings.Value;
    } else {
      return {};
    }

    field[x.Bindings] = x.Value;
    return field ?? {};
  }, {});

  return { ...bindingsFields, ...nonBindingsFields };
};

const debounceSkipCommand = debounce((fn, id, value) => {
  fn(true, id, value);
}, 2000);

const ActionNode = memo(
  ({ id, data }: any) => {
    const { action, onSave, context, onConnect, onDeleteActionNode, currentTriggerIndex, onChangeSkipCommand } = data;

    const confirmDeleteDialogOpen = useSignal(false);
    const rightHandle = useSignal<NextActionType | null>(action?.Handles?.[Handles.RIGHT] || null);
    const bottomHandle = useSignal<NextActionType | null>(action?.Handles?.[Handles.BOTTOM] || null);
    const anchorEl = useSignal<HTMLElement | null>(null);
    const menuOpen = useSignal<Handles | null>(null);
    const skipCommandInput = useSignal(action?.SkipCommand || "");

    const { getNodes, updateNodeData } = useReactFlow();
    const nodes = getNodes();
    const index = nodes.findIndex((node) => node.id === id) - 1;

    const formId = `${id}-actionPropEditor`;

    const actionOptions = actions?.map((action) => ({ label: action })) ?? [];
    const actionValue = action?.Name ?? actionOptions[0].label;

    if (id === "startNode") {
      return (
        <div>
          Start
          <Handle
            type="source"
            position={Position.Bottom}
            id={Handles.START}
            style={{ left: 30, background: "#6495ed", width: "8px", height: "8px" }}
            onConnect={(e) => onConnect(e)}
          />
        </div>
      );
    }

    const handleDelete = () => {
      confirmDeleteDialogOpen.value = true;
    };

    const onConfirmDelete = () => {
      onDeleteActionNode(id);
      confirmDeleteDialogOpen.value = false;
    };

    const handleChangeAction = (trigger, command, event, value) => {
      updateNodeData(id, { ...data, action: { Name: value, Parameters: [] } });
      onSave();
    };

    const propEditorActions = [
      {
        Trigger: "onSave",
        postHandler: onSave,
        ListenTo: ["onChange", "onRowUpdate"],
        CommandSet: {
          FirstCommandId: "1",
          ExecuteCommandsInParallel: false,
          Commands: [
            {
              Id: "1",
              SkipCommand: "!Form",
              Instruction: {
                Name: "SetState",
              },
              Parameters: [
                {
                  Name: "Bindings",
                  Value: {
                    [`State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Parameters`]:
                      "getFieldsWithBindingsFromForm(Form)",
                  },
                },
                {
                  Name: "Merge",
                  Value: false,
                },
              ],
            },
          ],
        },
      },
    ];

    const handleClose = () => {
      batch(() => {
        anchorEl.value = null;
        menuOpen.value = null;
      });
    };

    const handleOpenMenu = (event, handle) => {
      batch(() => {
        anchorEl.value = event.currentTarget;
        menuOpen.value = handle;
      });
    };

    const setSelectedActionType = (actionType) => {
      if (menuOpen.value === Handles.RIGHT) {
        if (!!rightHandle.value && rightHandle.value !== actionType)
          onConnect({ source: id, sourceHandle: Handles.RIGHT }, actionType, true);

        rightHandle.value = actionType;
      } else {
        if (!!bottomHandle.value && bottomHandle.value !== actionType)
          onConnect({ source: id, sourceHandle: Handles.BOTTOM }, actionType, true);

        bottomHandle.value = actionType;
      }

      anchorEl.value = null;
      menuOpen.value = null;
    };

    const getHandleBackground = (handle: Signal<NextActionType | null>) => {
      switch (handle.value) {
        case NextActionType.OK:
          return "lightseagreen";
        case NextActionType.FAIL:
          return "#ee2727";
        default:
          return "white";
      }
    };

    const handleOnChangeSkipCommandInput = (e) => {
      skipCommandInput.value = e.target.value;
      debounceSkipCommand(onChangeSkipCommand, id, e.target.value);
    };

    return (
      <div>
        <Stack direction="column" gap="0.5rem">
          <Stack direction="row" alignItems="center">
            <Checkbox
              onChange={(e, checked) => onChangeSkipCommand(checked, id, skipCommandInput.value)}
              data-testid={`${id}-skip-command-checkox`}
              checked={action?.SkipCommand !== undefined}
            />
            <Typography>Skip</Typography>
            <TextField
              data-testid={`${id}-skip-command-input`}
              className="prop-editor-input"
              sx={{
                marginLeft: "10px",
                "& .MuiInputBase-root": {
                  height: "40px",
                },
              }}
              onChange={handleOnChangeSkipCommandInput}
              value={skipCommandInput.value}
              disabled={action?.SkipCommand === undefined}
            />
          </Stack>
          <Stack direction="row">
            <NGMultiSelect
              context={context}
              config={{
                IgnoreLocalState: true,
                DefaultValue: actionValue,
                Value: actionValue,
                Id: `${id}-action-node-selector`,
                MultiSelectPossibleValues: actionOptions.map((action) => action.label),
                Style: {
                  flex: "1",
                  "& .MuiFormControl-root": {
                    margin: 0,
                  },
                  "& .MuiInputBase-root": {
                    padding: "1px 0px 1px 10px",
                  },
                },
                DisableClearable: true,
                Actions: [
                  {
                    Trigger: "onChange",
                    postHandler: handleChangeAction,
                    CommandSet: {
                      FirstCommandId: "0",
                      ExecuteCommandsInParallel: false,
                      Commands: [
                        {
                          Id: "0",
                          Instruction: {
                            Name: "SetState",
                          },
                          Parameters: [
                            {
                              Name: "Bindings",
                              Value: {
                                [`State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Instruction`]:
                                  "{'Name': Event}",
                                [`State.ActionEditor.Actions[${currentTriggerIndex}].CommandSet.Commands[${index}].Parameters`]:
                                  "[]",
                              },
                            },
                          ],
                          NextCommandIdOnSuccess: "2",
                        },
                        {
                          Id: "2",
                          Instruction: {
                            Name: "ClearForm",
                          },
                          Parameters: [{ Name: "Form", Value: formId }],
                        },
                      ],
                    },
                  },
                ],
              }}
            />
            <IconButton
              color="inherit"
              size="small"
              onClick={handleDelete}
              sx={{ width: "40px", maxWidth: "40px", height: "40px" }}
            >
              <Delete />
            </IconButton>
          </Stack>
          <NGPropertiesEditor
            context={context}
            config={{
              __typename: actionValue,
              Id: formId,
              UniqueName: formId,
              FormId: formId,
              Data: merge(fieldsToObject(action?.Parameters ?? []), { __typename: actionValue }),
              IgnoreLocalState: true,
              ShowBindingField: true,
              Actions: propEditorActions,
            }}
          />
        </Stack>
        <Handle type="target" position={Position.Left} style={{ left: 0, width: "12px", height: "12px" }} />
        <Handle
          type="source"
          position={Position.Right}
          id={Handles.RIGHT}
          style={{
            right: 0,
            background: getHandleBackground(rightHandle),
            width: "12px",
            height: "12px",
            border: !rightHandle.value ? "1px solid black" : "",
          }}
          isValidConnection={() => !!rightHandle.value}
          onClick={(e) => handleOpenMenu(e, Handles.RIGHT)}
          onConnect={(e) => onConnect(e, rightHandle.value)}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id={Handles.BOTTOM}
          style={{
            left: 150,
            bottom: 0,
            background: getHandleBackground(bottomHandle),
            width: "12px",
            height: "12px",
            border: !bottomHandle.value ? "1px solid black" : "",
          }}
          isValidConnection={() => !!bottomHandle.value}
          onClick={(e) => handleOpenMenu(e, Handles.BOTTOM)}
          onConnect={(e) => onConnect(e, bottomHandle.value)}
        />
        <ActionDialog
          open={confirmDeleteDialogOpen}
          onCancel={() => {
            confirmDeleteDialogOpen.value = false;
          }}
          onConfirm={onConfirmDelete}
          title="Confirm delete action"
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl.value}
          open={!!menuOpen.value}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            disabled={rightHandle.value === NextActionType.OK || bottomHandle.value === NextActionType.OK}
            onClick={() => setSelectedActionType(NextActionType.OK)}
          >
            Ok
          </MenuItem>
          <MenuItem
            disabled={rightHandle.value === NextActionType.FAIL || bottomHandle.value === NextActionType.FAIL}
            onClick={() => setSelectedActionType(NextActionType.FAIL)}
          >
            Fail
          </MenuItem>
        </Menu>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return isEqual({ ...prevProps.data, id: prevProps.id }, { ...nextProps.data, id: nextProps.id });
  }
);

export default ActionNode;
