const subscriptions: ((event: MessageEvent) => void)[] = [];

const bc = new BroadcastChannel("ng-native");

bc.onmessage = (event) => {
  console.log("Received message from channel", "ng-native", event.data);
  for (const sub of subscriptions) {
    sub(event);
  }
};

export function isNative() {
  return !!window.ReactNativeWebView;
}

export function subscribe(callback: (event: MessageEvent) => void) {
  console.log("Subscribing to channel", "ng-native", subscriptions, callback);
  subscriptions.push(callback);
}

export function unsubscribe(callback: (event: MessageEvent) => void) {
  const index = subscriptions.indexOf(callback);
  if (index > -1) {
    console.log("Unsubscribing from channel", "ng-native", subscriptions, callback);
    subscriptions.splice(index, 1);
  }
}

export function publish(action: string, params: any, componentInstanceId: string) {
  if (window.ReactNativeWebView) {
    console.log("Publishing message to channel", "ng-native", action, params, componentInstanceId);
    const payload = { action, params, componentInstanceId, source: "webview" };
    window.ReactNativeWebView.postMessage(JSON.stringify(payload));
    console.log("Sent message to channel", "ng-native", payload);
  }
}

export const native = {
  isNative,
  subscribe,
  unsubscribe,
  publish,
};
