export const categoryIcons = {
  DocumentAdded: "NoteAdd",
  DataModel: "AccountTreeOutlined",
  TaskComplete: "Task",
  Database: "Storage",
  CloudUpload: "CloudUpload",
  DataExtract: "Insights",
  DataExtractComplete: "TrendingUp",
  Complete: "CheckCircle",
  Image: "Image",
  Video: "VideoLibrary",
  Word: "Description",
  Excel: "TableChart",
  Powerpoint: "Slideshow",
  Pdf: "PictureAsPdf",
  Text: "TextSnippet",
  Audio: "LibraryMusic",
  Archive: "Archive",
  File: "InsertDriveFileIcon",
};

export const fileExtensionIcons = {
  png: "Image",
  jpg: "Image",
  webx: "VideoLibrary",
  docx: "Description",
  xlsx: "TableChart",
  pptx: "Slideshow",
  pdf: "PictureAsPdf",
  txt: "TextSnippet",
  mp4: "LibraryMusic",
  zip: "FolderZip",
  File: "InsertDriveFileIcon",
};
