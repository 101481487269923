import { attachInstruction, extractInstruction, Instruction } from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { attachClosestEdge, extractClosestEdge, Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { batch, signal } from "@preact/signals-react";
import { isNil, isObject } from "lodash";
import { act, SyntheticEvent } from "react";
import {
  Command,
  CommandSet,
  Component,
  IActionTrigger,
  ListColumn,
  Service,
  ServiceSnapshotOptions,
} from "../../resolvers-types";
import {
  NGNextPage,
  callService,
  clearForm,
  clearState,
  getContextMenu,
  getFormData,
  getModalPopup,
  getParams,
  getScope,
  getService,
  getState,
  iframeState,
  logState,
  meta,
  setupHandlers,
} from "./dataService";
import { client } from "./nats-client";
import {
  ApiToken,
  cloneDeepWithoutFunctions,
  delay,
  deleteCookie,
  generateUID,
  getApiUrl,
  getTenant,
  getUser,
  isNullOrEmpty,
  makeSlug,
  setCookie,
  updateWorkerToken,
} from "./utils";
import { getExprValue, setExprValue } from "./interpreter";
import { isEmpty } from "lodash-es";
import { jwtDecode } from "jwt-decode";
import { LogTag, log } from "./logger";
import { logoutClient } from "./security";
import { Result } from "../models/result";
import { IMoveItemData, RuntimeContext } from "./NGFieldExtensions";
import { SxProps, Theme } from "@mui/system";
import tracing from "./tracing";
import { buildMetadataMap, getItems, ItemNode } from "./metadataUtils";
import { generationMethods } from "../generators/codeGeneration/generationMethods";
import { ngEditorMenuSample } from "../sampleData/ngEditorMenuSample";
import { getOidc } from "./auth";
import { isNative } from "./native";

const tag: LogTag = "action";

type AsyncFunction<T = Element, E = Event> = (
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType,
  context: RuntimeContext
) => Promise<void>;

type ITransformMethod = (text: any, options: any | null) => any;

export async function executeAction<T = Element, E = Event>(
  action: IActionTrigger,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType,
  context: RuntimeContext
) {
  let lastExecution: any = null;

  if (!isNil(action.CommandSet) && !isNil(action.CommandSet.Commands) && action.CommandSet.Commands.length != 0) {
    if (action.CommandSet.ExecuteCommandsInParallel === true) throw "ExecuteCommandsInParallel not implemented yet";

    if (!isNil(action.SkipAction)) {
      // const formId = getParam("Form", action);
      // const componentId = getParam("Component", action);
      const { state, form, parentState } = getState(context, null, null);
      const scope = getScope(context, action, state, form, data, parentState);
      const shouldSkip = getExprValue(action.SkipAction, scope, null);

      if (shouldSkip) {
        log.info(
          tag,
          `Skipping action ${action.Trigger} because condition ${action.SkipAction} is ${shouldSkip}`,
          action
        );
        return;
      }
    }

    // an action has a command set within it
    lastExecution = await executeCommandSet(action, action.CommandSet, e, data, context);
  }

  if (!isEmpty(action.PropagateToParent) && !isNullOrEmpty(action.PropagateToParent.Trigger))
    if (
      action.PropagateToParent.PropagateOnFailure === true ||
      isEmpty(lastExecution) ||
      lastExecution.status !== "error"
    ) {
      for (let i = context.Path.length - 1; i >= 0; i--) {
        const target: any = context.Path[i]?.Config;
        const trigger = action.PropagateToParent.Trigger;

        if (!isNil(target) && !isEmpty(target.Actions) && !isNil(trigger)) {
          const parentAction = target.Actions.find((a) => a.Trigger == trigger);
          if (parentAction) {
            const targetContext = { ...context, Path: context.Path.slice(0, i) };
            const handlers = setupHandlers(target, targetContext);

            if (isNil(handlers[trigger])) {
              log.error(tag, `Target ${target.Id} doesn't have ${trigger} action`);
              return;
            }

            // if the user defines an event expression, evaluate it and pass it to the parent
            const expr = parentAction.PropagateToParent?.Event;
            if (!isNil(expr)) {
              data = getExprValue(expr, context, null);
            }

            handlers[trigger](e, data);
          }
        }
      }
    }
}

async function executeCommandSet<T = Element, E = Event>(
  action: IActionTrigger,
  commandSet: CommandSet,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType,
  context: RuntimeContext
) {
  // Find first command
  const commands = commandSet.Commands;

  log.info(tag, "executeCommandSet", commands);

  if (isNil(commands)) return;

  let nextCommandId = commandSet.FirstCommandId;

  while (!isNil(nextCommandId)) {
    const command = commands.find((c) => c?.Id === nextCommandId);

    if (isNil(command))
      throw `The Command with id ${nextCommandId} has not been found in command set${action.CommandSet?.Id}`;

    if (!isNil(command.SkipCommand)) {
      const formId = getParam("Form", command);
      const componentId = getParam("Component", command);
      const { state, form, parentState } = getState(context, componentId, formId);
      const scope = getScope(context, command, state, form, data, parentState);
      const shouldSkip = getExprValue(command.SkipCommand, scope, null);

      if (shouldSkip) {
        log.info(
          tag,
          `Skipping command ${command.Id} because condition ${command.SkipCommand} is ${shouldSkip}`,
          command
        );
        nextCommandId = command.NextCommandIdOnSuccess;
        continue;
      }
    }

    log.info(tag, "about to executeCommand", command, action, data, context);

    const r = await executeCommand(action, command, e, data, context);

    nextCommandId = r.nextCommandId;

    log.info(tag, "nextCommandId", nextCommandId, commands);

    if (isNil(nextCommandId)) return r;
  }
}

// This method takes the command and executes the relevant method associated with it
async function executeCommand<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<{ nextCommandId: string | null; status: string }> {
  if (isNil(command.Instruction)) return { nextCommandId: null, status: "skip" };

  const methodName: string = command.Instruction?.Name;

  const asyncFunc = asyncFunctions[methodName];

  if (!isNil(asyncFunc)) {
    try {
      const start = performance.now();

      const tag = `action.${methodName}`;

      log.groupDebug(tag, methodName, "Start");
      log.debug(tag, "command", command);
      log.debug(tag, "data", data);
      log.debug(tag, "context", context);
      // log.debug(tag, "formCtx", formCtx);
      log.groupEnd();

      const result = await asyncFunc(action, command, e as any, data, context);
      const end = performance.now();

      const { global, state, component, form, formParent, parentState } = getState(context);

      log.groupDebug(tag, methodName, "End", `, took: ${end - start}ms`);
      log.debug(tag, "command", command);
      log.debug(tag, "state", global);
      log.debug(tag, "parentState", parentState);
      log.debug(tag, "command result", result);
      log.groupEnd();

      // TODO: evaluate expression

      if (!isNil(command.NextCommand)) {
        const formId = getParam("Form", command);
        const componentId = getParam("Component", command);
        const { state, form, parentState } = getState(context, componentId, formId);
        const scope = getScope(context, command, state, form, data, parentState) as any;
        scope.CommandResult = result;
        const nextcommand = getExprValue(command.NextCommand, scope, null);

        return { nextCommandId: nextcommand, status: "evaluated" };
      }

      return { nextCommandId: command.NextCommandIdOnSuccess as string, status: "succes" };
    } catch (error) {
      return { nextCommandId: command.NextCommandIdOnFailure as string, status: "error" };
    }
  } else {
    throw `No implementation found for ${methodName}`;
  }
}

function getParam(paramName: string, command: Command, defaultValue: any = null) {
  if (isNil(command.Parameters)) return defaultValue;

  const nvp = command.Parameters.find((c) => c?.Name?.toLowerCase() === paramName.toLowerCase());

  if (isNil(nvp)) return defaultValue;

  return nvp.Value;
}

// -------------------------------------------------------------------------------------
// Command implementations
// -------------------------------------------------------------------------------------
async function ShowToast<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      batch(() => {
        const { global } = getState(context);
        const cfg = global["NGSnackbar"]["NGSnackbarMessage"];
        cfg.Open.value = true;
        cfg.Message.value = getParam("Message", command);
        cfg.Severity.value = getParam("Severity", command, "success");
        cfg.AutoHideDuration.value = getParam("AutoHideDuration", command, 3000);

        const commandResult = getParam("CommandResult", command, null);
        resolve(commandResult);
      });
    } catch (ex) {
      reject(ex);
    }
  });
}

async function ShowMessage<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      batch(() => {
        const { global } = getState(context);
        const cfg = global["NGDialog"]["NGMessage"];
        cfg.Open.value = true;
        cfg.ContentText.value = getParam("Message", command);
        cfg.Title.value = getParam("Title", command);
        cfg.ShowOkButton.value = getParam("ShowOkButton", command, true);
        cfg.ShowCancelButton.value = getParam("ShowCancelButton", command);
        cfg.ShowCloseButton.value = getParam("ShowCloseButton", command);
        cfg.OnClose = (result) => {
          if (cfg.ButtonClicked.value === "Cancel" || cfg.ButtonClicked.value === "Close") reject();
          else {
            if (!isNullOrEmpty(result)) resolve(result);
            else resolve();
          }
        };
      });
    } catch (ex) {
      reject(ex);
    }
  });
}

// type Progress = {
//   Id: string;
//   Message: string;
//   Done: boolean;
// };

async function CallService<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.CallService";
  return new Promise<void>((resolve, reject) => {
    (async () => {
      const input = {
        ServiceName: getParam("ServiceName", command),
        Form: getParam("Form", command),
        Component: getParam("Component", command),
        Service: getParam("Service", command),
        Fields: getParam("Fields", command),
      };

      try {
        const result = getParam("CommandResult", command, null);

        resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
          input[k] = srcValue;
          log.debug(tag, "expression", k, srcValue);
        });

        // TODO - VS review with Alex
        const getLocalService = (service: string) => {
          return {
            Config: signal(service),
            Loading: signal(false),
            Progress: signal([]),
            Data: signal({}),
          };
        };

        const service = isNil(input.Service)
          ? getService(context, input.ServiceName, input.Component)
          : getLocalService(input.Service);
        if (!service) {
          log.error(tag, `Service ${input.ServiceName} not found`);
          reject();
          return;
        }

        const config = service.Config.value;

        if (!isNil(input.Fields)) {
          const f2 = Object.entries(input.Fields).map(([k, v]) => ({ Name: k, Value: v }));

          config.Fields = [...config.Fields, ...f2];
        }

        // Take component id from the caller if it exists so the service can execute on in the correct component context
        const compId = command?.Parameters?.find((x) => x?.Name == "Component")?.Value;
        if (compId) {
          input.Component = compId;
        }
        const formId = command?.Parameters?.find((x) => x?.Name == "Form")?.Value;
        if (formId) {
          input.Form = formId;
        }

        // if either exists, force
        const force: boolean = !!input.Form || !!input.Component;

        const { state, form, parentState } = getState(context, input.Component, input.Form, force);
        const scope = getScope(context, config, state, form, data, parentState);

        if (config.Type == "Progress") {
          const [params, isValid] = getParams(config, scope);
          if (!isValid) {
            reject();
            return;
          }

          params.RequestId = generateUID();

          batch(() => {
            service.Loading.value = true;
            service.Progress.value = [];
          });
          client.subscribe(`progress.${params.RequestId}`, (resp: any) => {
            const msg = resp.data;
            service.Progress.value = service.Progress.value.concat(msg);
            log.info(tag, "Service", config.Service, "Progress", msg, service.Progress.value);
            if (msg.Done) {
              service.Loading.value = false;
              resolve(result);
            }
          });

          client.publish(config.Service, params);
        } else if (config.Type == "Publish") {
          const [params, isValid] = getParams(config, scope);
          if (!isValid) {
            reject();
            return;
          }

          client.publish(config.Service, params);
          resolve(result);
        } else {
          const success = await callService(config, context, true, data, state, form, scope, service);
          if (success) {
            resolve(result);
          } else {
            reject();
          }
        }
      } catch (ex) {
        log.error(tag, `Error while calling service ${input.ServiceName}`, ex);
        reject(ex);
      }
    })();
  });
}

type Scope = {
  Global: any;
  State: any;
  Event: object;
  Form: any;
  Config: Command;
  FormData: any;
  // FormConfig: any;
};

function resolveBindings(
  command: Command,
  bindingsParam: string,
  data: object,
  // formCtx: FormContextType,
  context: RuntimeContext,
  setTargetValue: (k: string, scope: Scope, srcValue: any, merge: boolean) => void
) {
  const bindings: { [key: string]: string } = getParam(bindingsParam, command);

  if (isEmpty(bindings)) return;

  // const formId = getParam("Form", command) ?? formCtx?.Form?.Id ?? null;
  // const form = formState[formId] ?? formCtx?.Data?.value ?? null;
  const formId = getParam("Form", command);
  const componentId = getParam("Component", command);
  const { state, form, parentState } = getState(context, componentId, formId);
  const scope = getScope(context, command, state, form, data, parentState);
  const merge = getParam("Merge", command, false);

  batch(() => {
    for (const [k, v] of Object.entries(bindings)) {
      let srcValue: any = null;
      if (v == "Form") {
        srcValue = getFormData(scope.Form);
      } else {
        srcValue = getExprValue(v, scope, null);
      }
      setTargetValue(k, scope, srcValue, merge);
    }

    logState(tag, command.Instruction?.Name ?? "State", context, componentId, formId);
  });
}

async function ClearForm<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      const formId = getParam("Form", command);
      const purge = getParam("Purge", command);
      const componentId = getParam("Component", command);
      const result = getParam("CommandResult", command, null);
      const { state, form, formParent } = getState(context, componentId, formId);
      if (form) {
        if (purge) {
          delete formParent.state[formId];
        } else {
          clearForm(form);
        }
      } else if (componentId) {
        log.warn(tag, `Form ${formId} is not found in component ${componentId}`);
      } else {
        log.warn(tag, `Form ${formId} is not found`);
      }

      logState(tag, "ClearForm", context, componentId, formId);

      resolve(result);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function Login<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.Login";
  return new Promise<void>((resolve, reject) => {
    try {
      const config = { Email: "", Password: "" };

      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        config[k] = srcValue;
      });

      const successRedirect = getParam("SuccessRedirect", command, "/");

      (async () => {
        const tracingHeaders = tracing.getHeaders();

        const resp = await fetch(`${getApiUrl()}/auth/login`, {
          method: "POST",
          headers: {
            ...tracingHeaders,
            "Content-Type": "application/json",
            Tenant: getTenant(),
          },
          body: JSON.stringify({
            Email: config.Email,
            Password: config.Password,
          }),
        });
        if (!resp.ok) {
          reject();
          return;
        }
        const result = await resp.json();
        const token = jwtDecode<ApiToken>(result.Token);
        // Expire 1hr from now if no expiration is provided
        const exp = new Date((token.exp ?? new Date().getTime() + 60 * 60) * 1000).toUTCString();
        document.cookie = `Api.Token=${result.Token}; path=/; expires=${exp}`;
        document.cookie = `Api.NatsJwt=${result.NatsJwt}; path=/; expires=${exp}`;
        document.cookie = `Api.NatsSeed=${result.NatsSeed}; path=/; expires=${exp}`;
        await delay(100);

        const { global } = getState(context);
        global["User"] = signal(getUser(token));

        tracing.createSessionId();

        // Have to redirect using full page reload so the top level route gets authenticated menu
        const url = new URL(window.location.href);
        const redirectPath = url.searchParams.get("Redirect");
        window.location.href = redirectPath ?? successRedirect;

        // updateWorkerToken("token.update", token);

        const commandResult = getParam("CommandResult", command, null);

        // redirectDocument(successRedirect);
        resolve(commandResult);
      })();
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function Logout<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.Logout";
  return new Promise<void>((resolve, reject) => {
    try {
      (async () => {
        const names = ["Api.Token", "Api.NatsJwt", "Api.NatsSeed", "Api.Branch"];
        names.forEach(deleteCookie);

        const { global } = getState(context);
        global["User"] = signal(null);

        if (isNative()) {
          await delay(100);
          logoutClient();
        } else {
          const auth = await getOidc();
          auth.logout({ redirectTo: "home" });
        }

        const commandResult = getParam("CommandResult", command, null);
        resolve(commandResult);
      })();
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function UpdatePassword<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.UpdatePassword";
  return new Promise<void>((resolve, reject) => {
    try {
      (async () => {
        // const names = ["Api.Token", "Api.NatsJwt", "Api.NatsSeed", "Api.Branch"];
        // names.forEach(deleteCookie);

        // const { global } = getState(context);
        // global["User"] = signal(null);
        const auth = await getOidc();
        auth.goToAuthServer({
          extraQueryParams: { kc_action: "UPDATE_PASSWORD" },
          // extraQueryParams: { kc_action: "CONFIGURE_TOTP" },
        });

        // await delay(100);
        const commandResult = getParam("CommandResult", command, null);
        // logoutClient();
        resolve(commandResult);
      })();
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}
async function SetupMFA<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.SetupMFA";
  return new Promise<void>((resolve, reject) => {
    try {
      (async () => {
        // const names = ["Api.Token", "Api.NatsJwt", "Api.NatsSeed", "Api.Branch"];
        // names.forEach(deleteCookie);

        // const { global } = getState(context);
        // global["User"] = signal(null);
        const auth = await getOidc();
        auth.goToAuthServer({
          extraQueryParams: { kc_action: "CONFIGURE_TOTP" },
        });

        // await delay(100);
        const commandResult = getParam("CommandResult", command, null);
        // logoutClient();
        resolve(commandResult);
      })();
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

function clearObject(target, source) {
  Object.keys(target).forEach((key) => {
    if ((key in source && source[key] === null) || source[key] === "") {
      delete target[key];
    } else if (source[key] && typeof source[key] === "object" && typeof target[key] === "object") {
      if (Array.isArray(source[key]) && Array.isArray(target[key])) {
        // When the property is an array, process each element
        const indexesToRemove: number[] = [];
        target[key].forEach((item, index) => {
          // If the length of source is greater than that of target, we remove the trailing elements in target
          if (index >= source[key].length) {
            indexesToRemove.push(index);
            return;
          }

          // if both are objects, we continue recursing
          if (isObject(item) && isObject(source[key][index])) clearObject(item, source[key][index]);
          else if (isNullOrEmpty(source[key][index]))
            // Not an object and source is blank
            indexesToRemove.push(index);
        });

        if (indexesToRemove.length > 0)
          target[key] = target[key].filter((_, index) => !indexesToRemove.includes(index));
      } else {
        // For non-array objects
        clearObject(target[key], source[key]);
      }
    }
  });
}

async function SetState<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  function setFn(left: any, idx: any, source: any): void {
    const target = left[idx];

    if (isObject(source)) {
      Object.keys(source).forEach((key) => {
        // if (!isNil(source[key]) && source[key] !== "") {
        target[key] = source[key];
        // }
      });

      // Map nulls but only as overrides
      // clearObject(target, source);

      // // Map nulls but only as overrides
      Object.keys(target).forEach((key) => {
        if ((key in source && source[key] === null) || source[key] === "") {
          delete target[key];
        }
      });
    }
  }

  return new Promise<void>((resolve, reject) => {
    try {
      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        if (merge) setExprValue(k, scope, srcValue, { merge: false, setFn: setFn });
        else setExprValue(k, scope, srcValue);

        log.debug(tag, "expression", k, srcValue);
      });

      resolve();
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function SetCookie<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const cookie = {};
  return new Promise<void>((resolve, reject) => {
    try {
      const tag = "action.SetCookie";
      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        log.info(tag, "expression", k, srcValue);
        setCookie(k, srcValue, 1);
        // setExprValue(k, scope, srcValue);
      });

      resolve();
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function DeleteCookie<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const cookie = {};
  return new Promise<void>((resolve, reject) => {
    try {
      const tag = "action.DeleteCookie";
      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        log.info(tag, "expression", k, srcValue);
        deleteCookie(k);
        // setExprValue(k, scope, srcValue);
      });

      resolve();
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function SendMessageToParent<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.SendMessageToParent";
  return new Promise<void>((resolve, reject) => {
    try {
      const msg = {
        Source: "ng",
      };

      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        log.info(tag, "expression", k, srcValue);
        msg[k] = srcValue;
        // setExprValue(k, scope, srcValue);
      });

      log.debug(tag, "Message", msg);
      const msg2 = cloneDeepWithoutFunctions(msg);
      window.parent.postMessage(msg2, "*");
      const commandResult = getParam("CommandResult", command, null);

      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function SendMessageToChild<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.SendMessageToChild";
  return new Promise<void>((resolve, reject) => {
    try {
      const msg = {
        Source: "ng",
        Actions: getParam("Actions", command),
        Type: getParam("Type", command),
      };

      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        msg[k] = srcValue;
      });

      const iframeId = getParam("IFrame", command);
      const iframeRef = iframeState[iframeId] ?? null;

      if (iframeRef) {
        log.debug(tag, "Message", msg);
        iframeRef.value.ContentWindow.postMessage(cloneDeepWithoutFunctions(msg));
      }

      const commandResult = getParam("CommandResult", command, null);

      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function OpenModalPopup<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.OpenModalPopup";
  return new Promise<void>((resolve, reject) => {
    try {
      batch(() => {
        const id = getParam("ModalPopupId", command, null);
        const componentId = getParam("Component", command, null);
        const cfg = getModalPopup(context, id, componentId);

        if (isNil(cfg)) throw `Modal popup with id ${id} not found`;

        cfg.Context = context;
        cfg.Open.value = true;
        cfg.Title.value = getParam("Title", command);
        cfg.ShowOkButton.value = getParam("ShowOkButton", command, true);
        cfg.ShowCancelButton.value = getParam("ShowCancelButton", command);
        cfg.ShowCloseButton.value = getParam("ShowCloseButton", command);
        cfg.OnClose = (result) => {
          if (cfg.ButtonClicked.value === "Cancel") reject();
          else {
            if (!isNullOrEmpty(result)) resolve(result);
            else resolve();
          }
        };
      });
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

// // Recursive function to handle the traversal and operation
// const clearStateForChildComponents = (componentId) => {
//   const { state, form, formParent } = getState(context, componentId, null);

//   // If the component is of type "Reference", clear its state
//   if (form && form.type === "Reference") {
//     clearForm(form);
//   }

//   // Traverse child components if they exist
//   if (formParent && formParent.components) {
//     formParent.components.forEach((child) => {
//       clearStateForChildComponents(child.Id);
//     });
//   }
// };

// const processComponents = (context, level) => {
//   if (level < 2 || !context || !context.Path || context.Path.length < level) {
//     return;
//   }

//   // Get the current component from the path
//   const currentComponent = context.Path[level - 1];

//   if (currentComponent.__typename === "Reference") {
//     clearForm(currentComponent);

//     // from the state, fetch this component, and clear it's state
//     findParentAndIndex(context, { Id: currentComponent.Id });
//   }

//   // Recursively process each component, assuming child components are contained in a property like `components`
//   if (currentComponent.Items) {
//     currentComponent.Items.forEach((child) => {
//       processComponents(context, level + 1);
//     });
//   }
// };

async function CloseModalPopup<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.CloseModalPopup";
  return new Promise<void>((resolve, reject) => {
    try {
      batch(() => {
        const id = getParam("ModalPopupId", command, null);
        const componentId = getParam("Component", command, null);
        const cfg = getModalPopup(context, id, componentId);

        if (isNil(cfg)) throw `Modal popup with id ${id} not found`;

        cfg.Open.value = false;

        // TODO: recursively go through all components of type Reference, using the component with componentId as a starting point
        // and run clearForm on them

        const resolver = getParam("Resolver", command, null);
        const result = getParam("CommandResult", command, null);

        if (!isNil(resolver)) {
          resolver();
        } else {
          if (!isNil(cfg.OnClose)) {
            cfg.OnClose(result);
          } else {
            if (cfg.ButtonClicked?.value === "Cancel") reject();
            else {
              if (!isNullOrEmpty(result)) resolve(result);
              else resolve();
            }
          }
        }
        resolve();
      });
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function GoToPage<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.GoToPage";
  return new Promise<void>((resolve, reject) => {
    try {
      let targetParams = "";
      let bFirst = true;

      const input = {
        Url: getParam("Page.Url", command),
      };

      resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      resolveBindings(
        command,
        "URLBindings",
        data,
        context,
        (k: string, scope: Scope, srcValue: any, merge: boolean) => {
          if (bFirst) bFirst = false;
          else targetParams += "&";
          targetParams += `${encodeURIComponent(k)}=${encodeURIComponent(srcValue)}`;
        }
      );

      const fullUrl = isNullOrEmpty(targetParams) ? input.Url : `${input.Url}?${targetParams}`;
      location.href = fullUrl;
      // if (input.Url.toLowerCase().startsWith("http")) location.href = fullUrl;
      // else {
      //   batch(() => {
      //     clearState("");
      //     NGNextPage.value = fullUrl;
      //   });
      // }
      const commandResult = getParam("CommandResult", command, null);

      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function GoToBookmark<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  context: RuntimeContext
): Promise<void> {
  const tag = "action.GoToBookmark";
  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        Bookmark: getParam("Bookmark", command),
      };

      resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      const commandResult = getParam("CommandResult", command, null);

      setTimeout(() => {
        const target = document.getElementById(input.Bookmark);
        if (target) {
          target.scrollIntoView({ behavior: "smooth" });
        } else {
          log.error(tag, `Bookmark ${input.Bookmark} not found`);
          reject(commandResult);
        }

        resolve(commandResult);
      }, 0);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function SendSMS<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.SendSMS";
  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        Number: getParam("Number", command),
        Message: getParam("Message", command),
      };

      resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      const encodedMessage = encodeURIComponent(input.Message);

      const separator = "&"; //TODO: Androis is ?
      // Set the href property
      window.location.href = "sms:" + input.Number + separator + "body=" + encodedMessage;

      const commandResult = getParam("CommandResult", command, null);
      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

//SendEmail
async function SendEmail<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  context: RuntimeContext
): Promise<void> {
  const tag = "action.SendEmail";
  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        To: getParam("To", command),
        Subject: getParam("Subject", command),
        CC: getParam("CC", command),
        Message: getParam("Message", command),
      };

      resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      const separator = "?";
      // Set the href property
      let cmd = "mailto:" + input.To + separator;

      if (!isNullOrEmpty(input.CC)) {
        cmd += "cc=" + input.CC + "&";
      }

      if (!isNullOrEmpty(input.Subject)) {
        cmd += "subject=" + encodeURIComponent(input.Subject) + "&";
      }

      if (!isNullOrEmpty(input.Message)) {
        cmd += "body=" + encodeURIComponent(input.Message);
      }

      window.location.href = cmd;
      const commandResult = getParam("CommandResult", command, null);
      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function CallPhone<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  context: RuntimeContext
): Promise<void> {
  const tag = "action.CallPhone";
  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        Number: getParam("Number", command),
      };

      resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      // Set the href property
      window.location.href = "tel:" + input.Number;

      const commandResult = getParam("CommandResult", command, null);
      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function Share<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.Share";
  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        SelectedUsers: [],
        Component: {},
        NoteType: null,
        Text: null,
        File: null,
      };

      resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      const users = input.SelectedUsers;
      const component: Component = input.Component as Component;

      if (isNil(component)) {
        throw "Component is null.  Check the bindings to the Share command";
      }

      if (isNil(users) || users.length == 0) {
        throw "No users to share with selected";
      }

      if (isNil(component.SharingOptions)) {
        throw "Component.SharingOptions is null.  Check the bindings to the Share command";
      }

      if (isNil(component.SharingOptions.ServiceSnapshotOptions)) {
        throw "Component.ServiceSnapshotOptions is null.  Check the bindings to the Share command";
      }

      const sharingObject: any = {
        ComponentId: component.SharingOptions.TargetComponentId ?? component.Id,
        ShareWith: users,
        Note: {
          NoteType: input.NoteType,
          Text: input.Text,
          File: input.File,
          Bucket: "notes",
        },
      };

      const serviceSnapshots: Service[] = [];
      const serviceSnapshotOptions = component.SharingOptions.ServiceSnapshotOptions;

      const { global, state } = getState(context);

      for (const serviceName in serviceSnapshotOptions) {
        // eslint-disable-next-line no-prototype-builtins
        if (!serviceSnapshotOptions.hasOwnProperty(serviceName)) continue;

        const o: ServiceSnapshotOptions = serviceSnapshotOptions[serviceName];
        const service = getService(context, serviceName);

        if (isNil(service)) {
          throw `Service ${serviceName} not found in metadata`;
        }

        let serviceSnapshot: Service = {};

        if (o.SnapServiceData === true) {
          serviceSnapshot = {
            Id: makeSlug(serviceName),
            UseSampleData: true,
            SampleData: service.Data.value,
          };
        } else if (o.SnapAllFields === true) {
          serviceSnapshot = {
            Id: makeSlug(serviceName),
            Fields: service.Config.value.Fields?.map((f) => {
              return {
                Name: f?.Name,
                Value: f?.Value,
              };
            }),
            UseSampleData: false,
          };
        }

        serviceSnapshots.push(serviceSnapshot);
      }

      sharingObject.ServiceSnapshots = serviceSnapshots;

      //SharingOptions: { CanShare: true, ServiceSnapshotOptions: { GetArticle: { SnapServiceData: true} } } }
      /*
      if (snapAllFields === true) {
        // we build the sharing object of the service based on the params passed
        // we grab all the fields from the service, which is in the metadata
        // Get the service metadata from metadata

        sharingObject.ServiceSnapshots = (metadata as any).Services.filter(
          (s) => component.Services?.find((s2) => s.Name === s2.Name)
        ).map((s) => {
          return {
            Id: makeSlug(s.Name),
            Fields: s.Fields.map((f) => {
              return {
                Name: f.Name,
                Value: f.Value,
              };
            }),
            UseSampleData: false,
          };
        });
      } else if (snapServiceData === true) {
        sharingObject.ServiceSnapshots = (metadata as any).Services.filter(
          (s) => component.Services?.find((s2) => s.Name === s2.Name)
        ).map((s) => {
          return {
            Id: makeSlug(s.Name),
            UseSampleData: true,
            SampleData: state.NGService[s.Name].Data.value,
          };
        });
      } else {
        //
      }
*/
      log.info(tag, "sharingObject", sharingObject);
      log.debug(tag, "state", global);
      const serviceName = "sharing.component.share";

      log.info(tag, "Service request", serviceName, sharingObject);
      (async () => {
        const resp: Result<any> = await client.request(serviceName, sharingObject);
        if (!resp?.success) {
          log.error(tag, `${serviceName} failed`, resp?.reasons);
          reject(resp?.reasons);
          return;
        }
        const commandResult = getParam("CommandResult", command, null);

        resolve(commandResult);
      })();
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function GoBack<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.GoBack";
  try {
    window.history.back();
  } catch (ex) {
    log.error(tag, "Error", command, ex);
    return new Promise<void>((resolve, reject) => {
      reject(ex);
    });
  }
}

async function OpenContextMenu<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.OpenContextMenu";
  return new Promise<void>((resolve, reject) => {
    try {
      if (isNil(e)) {
        log.error(
          tag,
          "Event is null.  This is necessary to know which element is triggering the context menu open.",
          command
        );
        reject();
        return;
      }

      const input = {
        ContextMenuId: getParam("ContextMenuId", command),
        Component: getParam("Component", command),
        Form: getParam("Form", command),
      };

      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      const commandResult = getParam("CommandResult", command, null);

      batch(() => {
        const cfg = getContextMenu(context, input.ContextMenuId, input.Component, input.Form);

        if (isNil(cfg)) {
          log.error(tag, `Context menu with id ${input.ContextMenuId} not found`);
          reject(commandResult);
        }

        cfg.Visible.value = true;
        cfg.AnchorElement.value = e.currentTarget ?? e.target;
      });

      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function CloseContextMenu<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.SendSMS";
  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        ContextMenuId: getParam("ContextMenuId", command),
        Component: getParam("Component", command),
      };

      resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      const commandResult = getParam("CommandResult", command, null);

      const cfg = getContextMenu(context, input.ContextMenuId, input.Component);

      if (isNil(cfg)) {
        log.error(tag, `Context menu with id ${input.ContextMenuId} not found`);
        reject(commandResult);
      }

      cfg.Visible.value = false;

      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

const ngExtractStateVariables: ITransformMethod = (component: any): { Id; Name }[] => {
  const regex = /State(\.\w+|\[\d+\])+/g;

  const text = isObject(component) ? JSON.stringify(component) : component;

  const matches = text.match(regex);

  if (isNil(matches)) return [];

  return Array.from(new Set(matches)).map((name, i) => ({ Id: i, Name: name }));
};

export const moveMetadataItem = (
  data: IMoveItemData,
  clonedItem?: object,
  cloneInstruction?: Instruction
): { Page: any; Changed: boolean } | undefined => {
  const { source, location } = data.DropData;
  const key = data.Key || "Items";
  const page = data.Page;
  let changed = false;
  const result = { Page: page, Changed: changed };
  // if source item does not exist within Page, added it to Page [key]
  page[key] = source?.data?.Template ? [...page[key], source?.data?.Template] : page[key];
  page[key] = clonedItem ? [...page[key], clonedItem] : page[key];

  const targets = location.current.dropTargets;
  if (targets.length == 0) return result;
  const localMeta = new Map<string, ItemNode>();
  buildMetadataMap(localMeta, page, null, 0, [0], [page.Id], key);
  const target = targets[0];
  const sourceNode = localMeta.get(source?.data?.id);
  const sourceData = sourceNode?.config;
  const sourceParent = localMeta.get(sourceNode?.parent ?? "");
  const targetNode = localMeta.get(target.data.id);
  // const targetData = targetNode?.config;
  const targetParent = localMeta.get(targetNode?.parent ?? "");
  const closestEdgeOfTarget: Edge | null = extractClosestEdge(target.data) ?? null;
  const instruction: Instruction | null = cloneInstruction ?? extractInstruction(target.data);

  // function moveItem(context: any, sourceData, location) {
  log.info(tag, "moveMetadataItem", localMeta, sourceNode, targetNode, closestEdgeOfTarget, instruction);

  if (instruction?.type === "instruction-blocked") return result;

  try {
    if (sourceNode?.parent == targetNode?.parent && instruction?.type != "make-child") {
      // reorder
      const items = targetParent?.config[key];
      const sourceIndex = sourceNode?.index ?? 0;
      let targetIndex = targetNode?.index ?? 0;

      if (sourceIndex < targetIndex) {
        // moving from top to bottom
        targetIndex += instruction?.type == "reorder-below" ? 0 : -1;
      } else {
        //moving from bottom to top
        targetIndex += instruction?.type == "reorder-below" ? 1 : 0;
      }

      if (sourceIndex != targetIndex) {
        const item = items.splice(sourceIndex, 1)[0];
        items.splice(targetIndex, 0, item);
        changed = true;
      }
    } else {
      let items = getItems(targetParent?.config, key);
      let index = (targetNode?.index ?? 0) + (instruction?.type == "reorder-below" ? +1 : 0);
      if (instruction?.type == "make-child") {
        items = getItems(targetNode?.config, key);
        index = items.length;
      }
      let item = sourceData;
      if (sourceNode != null) {
        item = sourceParent?.config[key].splice(sourceNode?.index, 1)[0];
      }
      items.splice(index, 0, item);
      changed = true;
    }
  } catch (ex) {
    log.error(
      tag,
      "moveMetadataItem",
      "Couldn't move item",
      "source",
      sourceNode,
      "target",
      targetNode,
      "instruction",
      instruction,
      ex
    );
    return undefined;
  }

  return { Page: page, Changed: changed };
};

const cssToJson: ITransformMethod = (cssText: string): SxProps<Theme> | undefined => {
  const styles = {};

  // Remove comments from CSS text
  const noCommentsCss = cssText.replace(/\/\*[\s\S]*?\*\//g, "");
  const cleanCssText = noCommentsCss.replace(/.*\{|\}/g, "").trim();

  cleanCssText.split(";").forEach((style) => {
    if (style.trim()) {
      const [key, value] = style.split(":");
      if (key && value) {
        const formattedKey = key.trim().replace(/-\w/g, (m) => m[1].toUpperCase());
        styles[formattedKey] = value.trim();
      }
    }
  });

  if (isEmpty(styles)) return undefined;

  return styles;
};

type DataRow = Record<string, any>;

const jsonArrayToDataGrid: ITransformMethod = (data: any): any | undefined => {
  if (isNil(data) || data.length === 0) return { Rows: [], Columns: [] };

  // Generate the header with alphabetical keys
  let idCounter = 0;

  const header: DataRow = { Id: idCounter++ };

  // Generate the superset of all keys present in any object
  const keys = keysSuperset(data);

  keys.forEach((key, index) => {
    header[key] = key.trim();
  });

  // Counter for unique Ids

  // Transform each object in the array
  const transformedData = data.map((obj) => {
    const transformedObj: DataRow = {};
    transformedObj["Id"] = idCounter++;
    keys.forEach((key, index) => {
      transformedObj[key] = obj[key];
    });
    return transformedObj;
  });

  // Insert the header at the beginning of the array
  //transformedData.unshift(header);

  return {
    Rows: transformedData,
    Columns: keysSuperset(transformedData).map((header) => ({
      Name: header,
      Type: "string",
      Width: "200",
      Editable: true,
      Visible: header != "Id",
    })) as any,
  };
};

const jsonToSpreadsheet: ITransformMethod = (data: any): any | undefined => {
  if (isNil(data) || data.length === 0) return { Rows: [], Columns: [] };

  // Generate the header with alphabetical keys
  let idCounter = 0;

  const header: DataRow = { Id: idCounter++ };

  // Generate the superset of all keys present in any object
  const keys = keysSuperset(data);

  keys.forEach((key, index) => {
    header[String.fromCharCode(65 + index)] = key.trim();
  });

  // Counter for unique Ids

  // Transform each object in the array
  const transformedData = data.map((obj) => {
    const transformedObj: DataRow = {};
    transformedObj["Id"] = idCounter++;
    keys.forEach((key, index) => {
      transformedObj[String.fromCharCode(65 + index)] = obj[key];
    });
    return transformedObj;
  });

  // Insert the header at the beginning of the array
  transformedData.unshift(header);

  return {
    Rows: transformedData,
    Columns: keysSuperset(transformedData).map((header) => ({
      Name: header,
      Type: "string",
      Width: "200",
      Editable: true,
      Visible: header != "Id",
    })) as any,
  };
};

const jsonToNameValuePair: ITransformMethod = (data: object): any | undefined => {
  const header: DataRow = [
    { Name: "Id", Type: "String", Editable: false, Visible: false },
    { Name: "Name", Type: "String", Editable: true, Width: "200" },
    { Name: "Value", Type: "String", Editable: true, Width: "400" },
  ];

  if (isNil(data) || Object.keys(data).length === 0)
    return {
      Rows: [],
      Columns: header,
    };

  // Generate the header with alphabetical keys
  let idCounter = 1;

  // Generate the list of all keys in the object
  const keys = Object.keys(data); //.sort();

  // Transform the object into an array of name-value pairs
  const transformedData = keys.map((key) => ({
    Id: idCounter++,
    Name: key,
    Value: data[key as keyof typeof data],
  }));

  return {
    Rows: transformedData,
    Columns: header,
  };
  // Insert the header at the beginning of the array
  //return [header, ...transformedData];
};

// Function to reverse the transformation
const spreadsheetToJson: ITransformMethod = (data: any): any[] | undefined => {
  if (data.length === 0) return [];

  // Extract the header (the first object) that contains the mapping
  const header = data[0];
  const reversedData = data.slice(1).map((obj) => {
    const reversedObj: DataRow = {};
    Object.keys(obj).forEach((key) => {
      if (key !== "Id") {
        // Assume 'Id' is not part of the original data properties
        const originalKey = header[key];
        reversedObj[originalKey] = obj[key];
      }
    });
    return reversedObj;
  });

  return reversedData;
};

//NameValuePairToJSON
const nameValuePairToJSON: ITransformMethod = (data: any): any | undefined => {
  if (data.length === 0) return {};

  const transformedData = data.map((obj) => {
    if (isNil(obj.Name)) return;
    return { [obj.Name]: obj.Value };
  });

  return Object.assign({}, ...transformedData);
};

type GridData = {
  Rows: any[];
  Columns: ListColumn[];
};

const excelToGrid: ITransformMethod = (data: any, options: any): GridData | undefined => {
  const lines = data.split("\n").filter((line) => line.trim() !== ""); // Split by newline and filter out empty lines

  if (options?.SingleRecord) {
    //const d = jsonToNameValuePair(transformedData, null);
    let idCounter = 0;

    const d: any[] = []; //[{ Id: idCounter++, Name: "Name", Value: "Value" }];

    lines.forEach((line) => {
      const cells = line.split("\t");
      const obj = { Id: idCounter++ };
      obj["Name"] = cells[0].trim();
      obj["Value"] = cells[1].trim();
      d.push(obj as any);
    });

    return {
      Rows: d,
      Columns: keysSuperset(d).map((header) => ({ Name: header, Type: "string", Editable: true })) as any,
    };
  } else {
    const headers = lines[0].split("\t"); // Assume headers are in the first row and split by tab
    const transformedData = lines.slice(1).map((line) => {
      const cells = line.split("\t");
      const obj = headers.reduce((acc, header, index) => {
        acc[header.trim()] = cells[index].trim(); // Trim header and cell values
        return acc;
      }, {} as any);
      return obj;
    });

    return jsonToSpreadsheet(transformedData, null);
  }
};

const transformMethods: { [key: string]: ITransformMethod } = {
  CSSToJSON: cssToJson,
  JSONToSpreadsheet: jsonToSpreadsheet,
  JSONArrayToDataGrid: jsonArrayToDataGrid,
  JSONToNameValuePair: jsonToNameValuePair,
  SpreadsheetToJSON: spreadsheetToJson,
  NameValuePairToJSON: nameValuePairToJSON,
  ExcelToGrid: excelToGrid,
  NGExtractStateVariables: ngExtractStateVariables,
  MoveMetadataItem: moveMetadataItem,
};

function keysSuperset(data: any) {
  const allKeys = new Set<string>();
  data.forEach((obj) => {
    Object.keys(obj).forEach((key) => allKeys.add(key));
  });
  return Array.from(allKeys);
}
//CopyToClipboard
async function CopyToClipboard<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.CopyToClipboard";
  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        TransformMethod: getParam("TransformMethod", command),
        Options: getParam("Options", command),
      };

      resolveBindings(command, "Bindings", {}, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      const text = input["ClipboardContent"];

      const content = isNullOrEmpty(input.TransformMethod)
        ? text
        : transformMethods[input.TransformMethod](text, input.Options);

      if (content === undefined) {
        log.error(tag, `Transform method ${input.TransformMethod} failed`);
        reject(`Transform method ${input.TransformMethod} failed`);
        return;
      }

      navigator.clipboard.writeText(content).then((text) => {
        const commandResult = getParam("CommandResult", command, null);
        resolve(commandResult);
      });
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function PasteFromClipboard<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.PasteFromClipboard";
  return new Promise<void>((resolve, reject) => {
    try {
      const transformMethod = getParam("TransformMethod", command);
      const options = getParam("Options", command);

      navigator.clipboard.readText().then((text) => {
        const content = isNullOrEmpty(transformMethod) ? text : transformMethods[transformMethod](text, options);

        if (content === undefined) {
          log.error(tag, `Transform method ${transformMethod} failed`);
          reject(`Transform method ${transformMethod} failed`);
          return;
        }

        resolveBindings(
          command,
          "Bindings",
          { ClipboardContent: content },
          context,
          (k: string, scope: Scope, srcValue: any, merge: boolean) => {
            setExprValue(k, scope, srcValue);
            log.debug(tag, "expression", k, srcValue);

            const commandResult = getParam("CommandResult", command, null);

            resolve(commandResult);
          }
        );
      });
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}
// DataTransformations

//triggerEvent,
async function TriggerAction<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.TriggerAction";

  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        Trigger: getParam("Trigger", command),
        TargetId: getParam("TargetId", command),
        Event: getParam("Event", command),
        Form: getParam("Form", command),
      };

      // First we get the bindings
      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      const target = meta.getNode(input.TargetId);

      if (isNil(target)) {
        log.error(tag, `Target with id ${input.TargetId} not found`);
        reject();
        return;
      }

      const stripTo = input.Form ?? input.TargetId;

      const ctx = target.Context ?? context;
      const targetContextIndex = ctx.Path.findIndex((x) => x.Id == stripTo);

      const targetContext = { ...ctx, Path: ctx.Path.slice(0, targetContextIndex + 1) };

      // if (!isNil(input.Form)) {
      //   targetContext.Form = {
      //     Config: meta.getNode(input.Form)?.config,
      //   };
      // }

      const handlers = setupHandlers(target.config, targetContext);

      if (isNil(handlers[input.Trigger])) {
        log.error(tag, `Target ${input.TargetId} doesn't have ${input.Trigger} action`);
        reject();
        return;
      }

      handlers[input.Trigger](e, input.Event ?? data);

      const commandResult = getParam("CommandResult", command, null);

      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function GenerateCode<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.GenerateCode";
  return new Promise<void>((resolve, reject) => {
    (async () => {
      try {
        const input = {
          GenerationType: getParam("GenerationType", command),
          InputData: getParam("InputData", command),
          Options: getParam("Options", command),
        };

        // First we get the bindings
        resolveBindings(
          command,
          "Bindings",
          data,
          context,
          (k: string, scope: Scope, srcValue: any, merge: boolean) => {
            input[k] = srcValue;
            log.debug(tag, "expression", k, srcValue);
          }
        );

        if (!isNullOrEmpty(input.InputData.ComponentName)) {
          input.InputData.ComponentTitle = input.InputData.ComponentTitle ?? input.InputData.ComponentName;
          input.InputData.ComponentName = input.InputData.ComponentName.replace(/ /g, "");
        }

        // Then we run the actual data transform
        const content = await generationMethods[input.GenerationType](input.InputData, input.Options);

        if (content === undefined) {
          log.error(tag, `Generation method ${input.GenerationType} failed`);
          reject(`Generation method ${input.GenerationType} failed`);
          return;
        }

        resolveBindings(
          command,
          "DataTransformations",
          { GeneratedData: content },
          context,
          (k: string, scope: Scope, srcValue: any, merge: boolean) => {
            setExprValue(k, scope, srcValue);
            log.debug(tag, "expression", k, srcValue);
          }
        );

        const commandResult = getParam("CommandResult", command, content ?? null);

        resolve(commandResult);
      } catch (ex) {
        log.error(tag, "Error", command, ex);
        reject(ex);
      }
    })();
  });
}

async function Transform<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.Transform";
  return new Promise<void>((resolve, reject) => {
    try {
      const input = {
        TransformMethod: getParam("TransformMethod", command),
        InputData: getParam("InputData", command),
        Options: getParam("Options", command),
      };

      // First we get the bindings
      resolveBindings(command, "Bindings", data, context, (k: string, scope: Scope, srcValue: any, merge: boolean) => {
        input[k] = srcValue;
        log.debug(tag, "expression", k, srcValue);
      });

      // Then we run the actual data transform
      const content = isNullOrEmpty(input.TransformMethod)
        ? input.InputData
        : transformMethods[input.TransformMethod](input.InputData, input.Options);

      if (content === undefined) {
        log.error(tag, `Transform method ${input.TransformMethod} failed`);
        reject(`Transform method ${input.TransformMethod} failed`);
        return;
      }

      resolveBindings(
        command,
        "DataTransformations",
        { TransformedData: content },
        context,
        (k: string, scope: Scope, srcValue: any, merge: boolean) => {
          setExprValue(k, scope, srcValue);
          log.debug(tag, "expression", k, srcValue);
        }
      );

      const commandResult = getParam("CommandResult", command, content ?? null);

      resolve(commandResult);
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

async function ReloadPage<T = Element, E = Event>(
  action: IActionTrigger,
  command: Command,
  e: SyntheticEvent<T, E> | null,
  data: object,
  // formCtx: FormContextType
  context: RuntimeContext
): Promise<void> {
  const tag = "action.SendSMS";
  return new Promise<void>((resolve, reject) => {
    try {
      location.reload(true);

      // There's no next command on reload
    } catch (ex) {
      log.error(tag, "Error", command, ex);
      reject(ex);
    }
  });
}

//const history = useHistory();
const asyncFunctions: { [key: string]: AsyncFunction } = {
  Share: Share,
  ShowMessage: ShowMessage,
  CallService: CallService,
  SetState: SetState,
  OpenModalPopup: OpenModalPopup,
  CloseModalPopup: CloseModalPopup,
  GoToPage: GoToPage,
  ClearForm: ClearForm,
  SendMessageToParent: SendMessageToParent,
  SendMessageToChild: SendMessageToChild,
  SendSMS: SendSMS,
  SendEmail: SendEmail,
  CallPhone: CallPhone,
  Login: Login,
  Logout: Logout,
  UpdatePassword: UpdatePassword,
  SetupMFA: SetupMFA,
  GoBack: GoBack,
  OpenContextMenu: OpenContextMenu,
  CloseContextMenu: CloseContextMenu,
  GoToBookmark: GoToBookmark,
  ReloadPage: ReloadPage,
  PasteFromClipboard: PasteFromClipboard,
  CopyToClipboard: CopyToClipboard,
  Transform: Transform,
  TriggerAction: TriggerAction,
  ShowToast: ShowToast,
  GenerateCode: GenerateCode,
  SetCookie: SetCookie,
  DeleteCookie: DeleteCookie,
  // UploadFile: UploadFile,
};
