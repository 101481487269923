import { useRef } from "react";
import { IconButton, Menu, Stack } from "@mui/material";
import { Close, Delete, DragIndicator, Key, Visibility, VisibilityOff, Add } from "@mui/icons-material";
import { batch, useSignal, useSignalEffect } from "@preact/signals-react";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import { isEmpty } from "lodash-es";

import { INGGridEditorProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import NGDropIndicatorBox from "../../generators/NGDropIndicatorBox";
import NGLabel from "../NGLabel/NGLabel";
import NGPropertiesEditor from "../../generators/NGPropertiesEditor";
import { generateUID, splitCamelCase } from "../../library/utils";
import { findParentPropEditorFormId } from "../../library/metadataUtils";

const propEditorId = "grid-column-prop-editor";

export default function NGGridEditor({ config, context }: INGGridEditorProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? []),
      Type: useSignal(config.Type ?? null),
      Data: useSignal(config.Data ?? null),
      SelectedIndex: useSignal(config.SelectedIndex ?? null),
      UseFormDialog: useSignal(config.UseFormDialog ?? null),
    },
    context
  );

  const handlers = setupHandlers(config, context);
  const anchorEl = useSignal<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl.value);

  const triggerOnChange = (newValue: unknown) => {
    try {
      local.Value.value = newValue
    } catch (error) {
      console.error(error)
    }

    if (handlers["onChange"]) {
      handlers["onChange"](new Event("onChange"), newValue ?? local.Value.value);
    }
  };

  useSignalEffect(() => {
    return combine(
      monitorForElements({
        canMonitor: ({ source }) => !!source.data?.__typename,
        onDrop({ location, source }) {
          const target = location.current.dropTargets[0];
          if (!target) {
            return;
          }

          const sourceData = source.data;
          const targetData = target.data;

          const indexOfTarget = local.Value.value.findIndex((item) => item.Id === targetData.Id);
          const startIndex = local.Value.value.findIndex((item) => item.Id === sourceData.Id) as number;

          if (indexOfTarget < 0) {
            return;
          }

          const closestEdgeOfTarget = extractClosestEdge(targetData);

          const finishIndex = getReorderDestinationIndex({
            startIndex,
            closestEdgeOfTarget,
            indexOfTarget,
            axis: "vertical",
          });

          if (finishIndex === startIndex) {
            // If there would be no change, we skip the update
            return;
          }

          const sortedColumns = reorder({
            list: local.Value.value,
            startIndex,
            finishIndex,
          });

          triggerOnChange(sortedColumns);
        },
      })
    );
  });

  const handleRemoveColumn = (index) => {
    const newColumns = [...(local.Value.value ?? [])];
    newColumns.splice(index, 1);
    anchorEl.value = null;
    triggerOnChange(newColumns);
  };

  const handleToggleColumnVisivility = (index) => {
    const newColumns = [...(local.Value.value ?? [])];
    newColumns[index].Visible = newColumns[index].Visible === undefined ? false : !newColumns[index].Visible;
    triggerOnChange(newColumns);
  };

  const handleCloseMenu = () => {
    anchorEl.value = null;
  };

  const handleAddNewColumn = () => {
    const newColumnId = generateUID();

    const newColumns = [
      ...(local.Value.value ?? []),
      config?.UseFormDialog ? {
        Id: newColumnId,
      } : {
        HeaderName: "New Column",
        Id: newColumnId,
        Name: newColumnId,
        IsPrimaryKey: local.Value.value?.length === 0 ?? false,
        __typename: "ListColumn",
      },
    ];
    triggerOnChange(newColumns);

  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, column, index) => {
    if (handlers["onSelectColumn"]) handlers["onSelectColumn"](new Event("select"), { Column: column, Index: index });
    if (handlers["onSelectRenderer"]) handlers["onSelectRenderer"](new Event("select"), { Column: column, Index: index });
    if (!local.UseFormDialog.value) {
      batch(() => {
        anchorEl.value = event.currentTarget;
      });
    }
  };

  const propertiesEditorFormId = findParentPropEditorFormId(context);

  return (
    <Stack display="flex" flexDirection="column" width="100%" gap="0.6rem">
      <Stack direction="row" justifyContent="space-between">
        <NGLabel config={{ Id: "grid-editor-title", Value: config.Label }} context={context} />
        <IconButton data-testid="column-editor-add-column" size="small" onClick={handleAddNewColumn}>
          <Add />
        </IconButton>
      </Stack>
      <Stack flexDirection="column">
        {!isEmpty(local.Value.value) &&
          local.Value.value?.map((column, index) => {
            return (
              <ColumnItem
                column={column}
                index={index}
                key={column.Id}
                onRemoveColumn={handleRemoveColumn}
                onToggleColumnVisivility={handleToggleColumnVisivility}
                handleOpenMenu={handleOpenMenu}
              />
            );
          })}
      </Stack>
      {!local.UseFormDialog.value && (
        <Menu
          id="column-editor-menu"
          className="column-editor-menu"
          anchorEl={anchorEl.value}
          open={menuOpen}
          sx={{
            "&.MuiPopover-root": {
              pointerEvents: "none",
              "& .MuiPaper-root": {
                pointerEvents: "all",
              },
            },
            "& .MuiMenu-paper": {
              marginLeft: "-24px", // Adjust the left margin as needed
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: 410,
          }}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
        >
          <Stack direction="column" width={340} margin={"6px 12px 10px 12px"} gap={1.2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={0.8}
              height="45px"
              style={{ position: "sticky", zIndex: 11, top: 0, background: "white" }}
            >
              {(local.Data.value?.HeaderName ?? local.Data.value?.Name) ?? "Headerless Column"}
              <Stack direction="row">
                <IconButton
                  data-testid="column-editor-visivility"
                  size="small"
                  onClick={() => handleToggleColumnVisivility(local.SelectedIndex.value)}
                >
                  {(local.Data.value?.Visible) === false ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                <IconButton
                  data-testid="column-editor-delete"
                  size="small"
                  onClick={() => handleRemoveColumn(local.SelectedIndex.value)}
                >
                  <Delete />
                </IconButton>
                <IconButton data-testid="column-editor-close-menu" size="small" onClick={handleCloseMenu}>
                  <Close />
                </IconButton>
              </Stack>
            </Stack>

            <NGPropertiesEditor
              config={{
                __typename: "PropertiesEditor",
                Id: propEditorId,
                FormId: propEditorId,
                UniqueName: propEditorId,
                UseFieldGroups: true,
                Bindings: {
                  Data: "State.GridEditorData",
                },
                Actions: [
                  {
                    Trigger: "onSave",
                    CommandSet: {
                      FirstCommandId: "1",
                      ExecuteCommandsInParallel: false,
                      Commands: [
                        {
                          Id: "1",
                          Instruction: {
                            Name: "SetState",
                          },
                          Parameters: [
                            {
                              Name: "Bindings",
                              Value: {
                                "State.GridEditorState": "merge(State.GridEditorData,getFormData(Form))",
                              },
                            },
                          ],
                          NextCommandIdOnSuccess: "2",
                        },
                        {
                          Id: "2",
                          Instruction: {
                            Name: "SetState",
                          },
                          Parameters: [
                            {
                              Name: "Bindings",
                              Value: {
                                "State.GridEditorData": "merge(State.GridEditorData,getFormData(Form))",
                                [`State.NGForm.${propertiesEditorFormId}.ListColumns[${local.SelectedIndex.value}]`]: "State.GridEditorData",
                              },
                            },
                            {
                              Name: "Merge",
                              Value: true,
                            },
                          ],
                        },
                      ],
                    },
                  },
                ],
              }}
              context={context}
            />
          </Stack>
        </Menu>
      )}
    </Stack>
  );
}

const ColumnItem = ({
  column,
  index,
  onRemoveColumn,
  onToggleColumnVisivility,
  handleOpenMenu,
}) => {
  const dragState = useSignal<{ closestEdge: Edge | null }>({ closestEdge: null });
  const ref = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLButtonElement>(null);

  useSignalEffect(() => {
    const element = ref.current;
    const dragHandle = handleRef.current;

    if (!element || !dragHandle) return;

    return combine(
      draggable({ element, dragHandle, getInitialData: () => column }),
      dropTargetForElements({
        element,
        getData({ input }) {
          return attachClosestEdge(column, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          dragState.value = { closestEdge };
        },
        onDragLeave() {
          dragState.value = { closestEdge: null };
        },
        onDrop() {
          dragState.value = { closestEdge: null };
        },
      })
    );
  });

  const columnName = column?.HeaderName ?? column?.Name ?? splitCamelCase(column?.__typename) ?? "Headerless Column";

  return (
    <Stack data-testid="grid-editor" direction="row" justifyContent="space-between" ref={ref} position="relative">
      <Stack direction="row" alignItems="center" flex={1}>
        <IconButton size="small" ref={handleRef} data-testid={`grid-editor-column-${column.Name}-drag`}>
          <DragIndicator />
        </IconButton>
        <div onClick={(e) => handleOpenMenu(e, column, index)} style={{ flex: 1 }}>
          {columnName}
        </div>
      </Stack>
      <Stack direction="row" alignItems="center">
        {column.IsPrimaryKey && <Key data-testid={`grid-editor-column-${columnName}-key`} />}
        <IconButton
          data-testid={`grid-editor-column-${columnName}-visivility`}
          size="small"
          onClick={() => onToggleColumnVisivility(index)}
        >
          {column.Visible === false ? <VisibilityOff /> : <Visibility />}
        </IconButton>
        <IconButton
          data-testid={`grid-editor-column-${columnName}-delete`}
          size="small"
          onClick={() => onRemoveColumn(index)}
        >
          <Delete />
        </IconButton>
      </Stack>

      {dragState.value.closestEdge && <NGDropIndicatorBox edge={dragState.value.closestEdge} />}
    </Stack>
  );
};
