import { IconButton, Stack, TextField, Typography } from "@mui/material";

import { Add, Delete } from "@mui/icons-material";
import { useSignal } from "@preact/signals-react";
import { INGBindingEditorProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { debouncedHandler } from "../../library/utils";

const NGBindingEditor = ({ config, context }: INGBindingEditorProps) => {
  const local = setupLocalState(config, { Value: useSignal(config.Value ?? {}) }, context);
  const handlers = setupHandlers(config, context);
  const bindings = local.Value;

  const bindingsValues = useSignal(
    Object.entries(bindings.value ?? {}).map(([key, value]) => ({
      key,
      value,
    }))
  );

  function convertArrayToObject(array: any[]): Record<string, string> {
    return array.reduce((acc, item) => ({ ...acc, [item.key]: item.value }), {});
  }

  const updateValues = (e?) => {
    local.Value.value = convertArrayToObject(bindingsValues.value);

    if (handlers.onChange) {
      debouncedHandler(handlers.onChange, e, local.Value.value);
    }
  };

  const handleDeleteBinding = (e, index: number) => {
    bindingsValues.value.splice(index, 1);
    updateValues(e);
  };

  const handleKeyChange = (e, index: number, value: string) => {
    bindingsValues.value[index].key = value;
    updateValues(e);
    e.stopPropagation();
  };

  const handleValueChange = (e, index: number, value: string) => {
    bindingsValues.value[index].value = value;
    updateValues(e);
    e.stopPropagation();
  };

  const handleAddBinding = (e) => {
    bindingsValues.value.push({ key: "", value: "" });
    updateValues(e);
  };

  return (
    <Stack gap="0.1rem" sx={{ width: "100%" }} marginBottom={bindingsValues.value.length > 0 ? "10px" : ""}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography className="control-label">{config.Title}</Typography>
        <IconButton
          color="inherit"
          size="small"
          onClick={handleAddBinding}
          sx={{ flex: "none !important", width: "40px", maxWidth: "40px", height: "40px" }}
        >
          <Add />
        </IconButton>
      </Stack>
      {bindingsValues.value.length > 0 && (
        <Stack direction="column" gap="0.6rem" alignItems="center">
          {bindingsValues.value.map(({ key, value }, index) => (
            <Stack direction="row" key={index} alignItems="center" width="100%">
              <Stack direction="column" gap="0.2rem" key={index} alignItems="center" flex="auto">
                <div></div>
                <TextField
                  size="small"
                  placeholder="Name"
                  value={key}
                  onChange={(e) => handleKeyChange(e, index, e.target.value)}
                  className="prop-editor-input"
                />
                <TextField
                  size="small"
                  placeholder="Value"
                  value={value}
                  onChange={(e) => handleValueChange(e, index, e.target.value)}
                  className="prop-editor-input"
                />
              </Stack>
              <IconButton
                color="inherit"
                size="small"
                onClick={(e) => handleDeleteBinding(e, index)}
                className="prop-editor-action-button"
                sx={{ width: "40px", maxWidth: "40px", height: "40px" }}
              >
                <Delete />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default NGBindingEditor;
