import * as graphql from "../../resolvers-types";

export const popupServices: graphql.Service[] = [
  {
    Name: "uiitems.query",
    Service: "uiitems.query", // TODO: Change to styles.query
    UseSampleData: true,
    Fields: [],
    Trigger: null,
    Bindings: {
      "State.ItemTypes": "Result.Items || []",
    },
    SampleData: {
      Items: [
        "Page",
        "SimpleContainer",
        "TabLayout",
        "AIDiasetPageCommandlog",
        "VisibleMenu",
        "MultiSelect",
        "Button",
        "Checkbox",
        "BasicInput",
        "Label",
        "RadioGroup",
        "Slider",
        "Switch",
        "TabContainer",
        "InputField",
        "CodeEditor",
        "Snackbar",
        "Form",
        "List",
        "ListColumn",
        "TreeList",
        "Feed",
        "Repeater",
        "Chart",
        "ComponentLibrary",
        "IFrame",
        "Image",
        "Avatar",
        "FileUpload",
        "ResolutionButtons",
        "Icon",
        "Component",
        "AudioPlayer",
        "AudioRecorder",
        "ProportionalBar",
        "Html",
        "ContextMenu",
        "DatePicker",
        "Link"
      ],
    },
  }, //StyleDetails
  {
    Name: "styles-get",
    Service: "styles.get",
    Require: "State.PopupSelectedStyle",
    Fields: [
      {
        Name: "ThemeName",
        //Required: true,
        Bindings: {
          Value: "State.NGTheme.Name || 'communify'",
        },
      },
      {
        Name: "StyleName",
        Required: true,
        Value: null,
        Bindings: {
          Value: "State.PopupSelectedStyle",
        },
      },
      {
        Name: "IgnoreIfNotFound",
        Value: true,
      },
    ],
    Bindings: {
      "State.PopupSelectedStyleQualifiers": "Result.Qualifiers || ''",
      "State.StyleDetails": "keys(Result.Value).length > 0 ? Result.Value : State.StyleDetails",
    },
  },
  {
    Name: "styles-merge",
    Service: "styles.merge",
    Trigger: "Action",
    Bindings: {
      "State.NGTheme.Styles[Result.FullClassName]": "Result.Value || State.NGTheme.Styles[State.PopupSelectedClass]",
      "State.PopupSelectedClass": "Result.FullClassName",
    },
    Fields: [
      {
        Name: "ThemeName",
        //Required: true,
        Bindings: {
          Value: "State.NGTheme.Name || 'communify'",
        },
      },

      //     public string Key { get; set; }
      //   public string FullClassName { get; set; }
      //   public string[] Qualifiers { get; set; }
      //   public JsonNode Value{ get; set; }
      {
        Name: "Style",
        //Required: true,
        Value: {},
        Bindings: {
          Value:
            "{ Key: State.PopupSelectedStyle, Qualifiers: State.PopupSelectedStyleQualifiers, Value: State.StyleDetails}",
        },
      },
    ],
  },
  {
    Name: "formats-get",
    Service: "formats.get",
    Require: "State.PopupSelectedFormat",
    Fields: [
      {
        Name: "SiteName",
        //Required: true,
        Bindings: {
          Value: "State.NGSite.Name",
        },
      },
      {
        Name: "FormatName",
        Required: true,
        Value: null,
        Bindings: {
          Value: "State.PopupSelectedFormat",
        },
      },
    ],
    Bindings: {
      "State.PopupSelectedFormatQualifiers": "Result.Qualifiers || ''",
      "State.FormatDetails": "Result.Value || {}",
    },
  },
  {
    Name: "formats-merge",
    Service: "formats.merge",
    Trigger: "Action",
    Fields: [
      {
        Name: "SiteName",
        //Required: true,
        Bindings: {
          Value: "State.NGSite.Name",
        },
      },

      //     public string Key { get; set; }
      //   public string FullClassName { get; set; }
      //   public string[] Qualifiers { get; set; }
      //   public JsonNode Value{ get; set; }
      {
        Name: "Format",
        //Required: true,
        Value: {},
        Bindings: {
          Value: "{ Name: State.PopupSelectedFormat, Value: State.FormatDetails}",
        },
      },
    ],
  },
] as graphql.Service[];

export const editStyles: graphql.Dialog = {
  __typename: "Dialog",
  Id: "EditStylesPopup",
  Title: "Edit Styles",
  ContentContainer: {
    __typename: "SimpleContainer",
    Id: "/1/",
    Items: [
      {
        Typename: "Form",
        Id: "EditStylesForm",
        UniqueName: "EditStylesForm",
        Items: [
          {
            __typename: "MultiSelect",
            Name: "ItemType",
            Id: "itemtype",
            Label: "Item Type",
            Disabled: true,
            Bindings: {
              MultiSelectPossibleValues: "State.ItemTypes",
              Value: "State.PopupTypename",
            },
          },
          {
            __typename: "Label",
            Style: {
              paddingTop: "1em",
            },
            Id: "0xa1ws8",
            Value: "Select and existing style or create a new one:",
          },
          {
            __typename: "MultiSelect",
            Name: "Style",
            Id: "Style",
            Label: "Style Class Name",
            //FreeSolo: true,
            // LabelExpression: "Key",
            // ValueExpression: "Key",
            Bindings: {
              MultiSelectPossibleValues: "State.PopupStyles",
              Value: "State.PopupSelectedStyle",
            },
            Actions: [
              {
                Trigger: "onChange",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.PopupSelectedClass": `['.', State.PopupSelectedStyle].join('')`,
                            "State.StyleDetails": "State.NGTheme.Styles[State.PopupSelectedClass]",
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            __typename: "MultiSelect",
            Name: "ExtraQualifiers",
            Id: "ExtraQualifiers",
            Label: "Additional Qualifiers",
            FreeSolo: true,
            Multiple: true,
            Bindings: {
              Value: "State.PopupSelectedStyleQualifiers",
            },
          },

          {
            __typename: "SimpleContainer",
            Id: "simpleContainer-1",
            Layout: {
              __typename: "FlexLayout",
              Id: "21",
              Direction: "row",
              Gap: "1em",
              JustifyContent: "space-between",
              AlignItems: "center",
            },
            Items: [
              {
                __typename: "Label",
                Style: {
                  paddingTop: "1em",
                  paddingBottom: "0.5em",
                  width: "fit-content",
                  //flexBasis: "80%",
                },
                Id: "0xa1wssws8",
                Value:
                  "Enter the style properties here using camelCase notation (e.g. backgroundColor, not background-color) or paste from Figma/CSS:",
              },
              {
                __typename: "Button",
                Name: "PasteFromCSS",
                Id: "PasteFromCSSButton",
                // Label: "Paste form Figma/CSS",
                StartIcon: { __typename: "Icon", IconName: "AutoFixHigh" },
                EndIcon: { __typename: "Icon", IconName: "ContentPaste" },
                Variant: "outlined",
                // Style: {
                //   flexBasis: "20%",
                // },
                Actions: [
                  {
                    Trigger: "onClick",
                    CommandSet: {
                      FirstCommandId: "1",
                      ExecuteCommandsInParallel: false,
                      Commands: [
                        {
                          Id: "1",
                          Instruction: {
                            Name: "PasteFromClipboard",
                          },
                          Parameters: [
                            { Name: "TransformMethod", Value: "CSSToJSON" },
                            { Name: "Bindings", Value: { "State.StyleDetails": "Event.ClipboardContent" } },
                          ],
                          NextCommandIdOnFailure: "2",
                          NextCommandIdOnSuccess: "3",
                        },
                        {
                          Id: "2",
                          Instruction: {
                            Name: "ShowMessage",
                          },
                          Parameters: [
                            {
                              Name: "Message",
                              Value: "Cannot paste from clipboard.",
                            },
                            { Name: "ShowOkButton", Value: true },
                          ],
                        },
                        {
                          Id: "3",
                          SkipCommand: "State.SelectedId == Event.Id",
                          Instruction: {
                            Name: "CallService",
                          },
                          Parameters: [
                            { Name: "ServiceName", Value: "styles-merge" },
                            { Name: "Form", Value: "EditStylesForm" },
                          ],
                          NextCommandIdOnSuccess: "4",
                        },
                        {
                          Id: "4",
                          Instruction: {
                            Name: "CallService",
                          },
                          Parameters: [
                            {
                              Name: "ServiceName",
                              Value: "styles-query",
                            },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          } as graphql.SimpleContainer,
          {
            __typename: "StyleEditor",
            Id: "Value",
            Name: "Value",
            ShowSaveClass: false,
            Width: "100%",
            // Value: "State.StyleDetails",
            Bindings: {
              Value: "State.StyleDetails",
              Type: "State.PropertiesEditorTypeName",
            },
          },
          {
            __typename: "Button",
            Id: "3",
            Name: "Add",
            Label: "Save",
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Name: "CallService",
                      },
                      Parameters: [
                        { Name: "ServiceName", Value: "styles-merge" },
                        { Name: "Form", Value: "EditStylesForm" },
                      ],
                      NextCommandIdOnSuccess: "1",
                    },
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SendMessageToChild",
                      },
                      Parameters: [
                        {
                          Name: "IFrame",
                          Value: "EditorIFrame",
                        },
                        {
                          Name: "Type",
                          Value: "stylesUpdated",
                        },
                      ],
                      NextCommandIdOnSuccess: "2",
                    },
                    {
                      Id: "2",
                      Instruction: {
                        Name: "ClearForm",
                      },
                      Parameters: [{ Name: "Form", Value: "EditStylesForm" }],
                      NextCommandIdOnSuccess: "3",
                    },
                    {
                      Id: "3",
                      Instruction: {
                        Name: "CloseModalPopup",
                      },
                      Parameters: [
                        { Name: "ModalPopupId", Value: "EditStylesPopup" },
                        { Name: "CommandResult", Value: "Save" },
                      ],
                      NextCommandIdOnSuccess: "4",
                    },
                    {
                      Id: "4",
                      Instruction: {
                        Name: "CallService",
                      },
                      Parameters: [
                        {
                          Name: "ServiceName",
                          Value: "styles-query",
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            __typename: "Button",
            Name: "CloseModal",
            Id: "4",
            Label: "Close",
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "CloseModalPopup",
                      },
                      Parameters: [{ Name: "ModalPopupId", Value: "EditStylesPopup" }],
                    },
                  ],
                },
              },
            ],
          },
        ],
        Layout: {
          __typename: "LinearLayout",
          Id: "21",
          // SpacingExtraSmall: "1em",
          DirectionExtraSmall: "column",
        },
      } as graphql.Form,
    ],
  } as graphql.SimpleContainer,
};

export const editCellLayout: graphql.Dialog = {
  __typename: "Dialog",
  Id: "EditCellLayoutPopup",
  Title: "Cell Layout Editor",
  ContentContainer: {
    __typename: "SimpleContainer",
    Id: "/1/",
    Items: [
      {
        __typename: "MultiSelect",
        Name: "ItemType",
        Id: "itemtype",
        Label: "Item Type",
        // Disabled: true,
        Bindings: {
          MultiSelectPossibleValues: "State.ItemTypes",
          Value: "State.CellLayoutTypename",
        },
        Actions: [
          {
            Trigger: "onChange",
            CommandSet: {
              FirstCommandId: "0",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "0",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        "State.CellLayout": "merge(State.CellLayout || {}, { __typename: Event })",
                        "State.CellLayoutState": "State.CellLayout",
                        "State.CellLayoutTypename": "Event",
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      {
        __typename: "PropertiesEditor",
        Id: "EditCellLayoutEditor",
        FormId: "CellLayoutForm",
        UseFieldGroups: true,
        UniqueName: "EditCellLayoutEditor",
        Bindings: {
          Data: "State.CellLayout",
        },
        Actions: [
          {
            Trigger: "onSave",
            CommandSet: {
              FirstCommandId: "0",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "0",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Form",
                      Value: "CellLayoutForm",
                    },
                    {
                      Name: "Bindings",
                      Value: {
                        "State.CellLayoutState": "merge(State.CellLayout,getFormData(Form))",
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      } as graphql.PropertiesEditor,
    ],
  } as graphql.SimpleContainer,
  FooterContainer: {
    __typename: "SimpleContainer",
    Id: "/1/",
    Style: { width: "100%" },
    Items: [
      {
        __typename: "Button",
        Id: "EditCellLayoutButton",
        Label: "Save",
        Style: {
          fontWeight: 500,
          fontSize: "1rem",
          color: "#000",
        },
        Actions: [
          {
            Trigger: "onClick",
            CommandSet: {
              FirstCommandId: "0",
              ExecuteCommandsInParallel: false,
              Commands: [
                {
                  Id: "0",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      // need to save the form into cellrenderer array
                      Value: {
                        "State.CellLayout": "State.CellLayoutState",
                        [`State.GridEditorData.CellRendererItems[State.CellLayoutIndex]`]: "State.CellLayoutState",
                        "State.GridEditorState": "State.GridEditorData",
                      },
                    },
                  ],
                  NextCommandIdOnSuccess: "1",
                },
                {
                  Id: "1",
                  Instruction: {
                    Name: "ClearForm",
                  },
                  Parameters: [{ Name: "Form", Value: "CellLayoutForm" }],
                  NextCommandIdOnSuccess: "2",
                },
                {
                  Id: "2",
                  Instruction: {
                    Name: "CloseModalPopup",
                  },
                  Parameters: [
                    { Name: "ModalPopupId", Value: "EditCellLayoutPopup" },
                    { Name: "CommandResult", Value: "Save" },
                  ],
                  NextCommandIdOnSuccess: "3",
                },
                {
                  Id: "3",
                  Instruction: {
                    Name: "SetState",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        ["State.NGForm.NGPropEditor.ListColumns[State.GridEditorDataIndex]"]: "State.GridEditorData",
                      },
                    },
                    {
                      Name: "Merge",
                      Value: true,
                    },
                  ],
                  NextCommandIdOnSuccess: "4",
                },
                {
                  Id: "4",
                  Instruction: {
                    Name: "TriggerAction",
                  },
                  Parameters: [
                    {
                      Name: "Trigger",
                      Value: "onSave",
                    },
                    {
                      Name: "TargetId",
                      Value: "PropertiesEditor/A/1",
                    },
                    {
                      Name: "Form",
                      Value: "NGPropEditor",
                    },
                  ],
                  NextCommandIdOnSuccess: "5",
                },
                {
                  Id: "5",
                  Instruction: {
                    Name: "SendMessageToParent",
                  },
                  Parameters: [
                    {
                      Name: "Bindings",
                      Value: {
                        Data: "State.GridEditorData",
                        Type: "'onGridEditorUpdate'",
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      } as graphql.Button,
    ],
  } as graphql.SimpleContainer,
};

export const editFormats: graphql.Dialog = {
  __typename: "Dialog",
  Id: "EditFormatsPopup",
  Title: "Edit Formats",
  ContentContainer: {
    __typename: "SimpleContainer",
    Id: "/1/",
    Items: [
      {
        Typename: "Form",
        Id: "EditFormatsForm",
        UniqueName: "EditFormatsForm",
        Items: [
          {
            __typename: "MultiSelect",
            Name: "ItemType",
            Id: "itemtype",
            Label: "Item Type",
            Disabled: true,
            Bindings: {
              MultiSelectPossibleValues: "State.ItemTypes",
              Value: "State.PopupTypename",
            },
          },
          {
            __typename: "Label",
            Format: {
              paddingTop: "1em",
            },
            Id: "0xa1ws8",
            Value: "Select and existing format or create a new one:",
          },
          {
            __typename: "InputField",
            Name: "Format",
            Id: "Format",
            Label: "Format Name",
            Disabled: true,
            //FreeSolo: true,
            // LabelExpression: "Key",
            // ValueExpression: "Key",
            Bindings: {
              //MultiSelectPossibleValues: "State.PopupFormats",
              Value: "State.PopupSelectedFormat",
            },
          },
          {
            __typename: "Label",
            Format: {
              paddingTop: "1em",
              paddingBottom: "0.5em",
            },
            Id: "0xa1wssws8",
            Value: "Enter the format properties here:",
          },
          {
            __typename: "CodeEditor",
            Id: "Value",
            Name: "Value",
            Multiline: true,
            Height: "350px",
            Width: "700px",
            Bindings: {
              Value: "State.FormatDetails",
            },
          },
          {
            __typename: "Button",
            Id: "3",
            Name: "Add",
            Label: "Save",
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Name: "CallService",
                      },
                      Parameters: [
                        { Name: "ServiceName", Value: "formats-merge" },
                        { Name: "Form", Value: "EditFormatsForm" },
                      ],
                      NextCommandIdOnSuccess: "1",
                    },
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SendMessageToChild",
                      },
                      Parameters: [
                        {
                          Name: "IFrame",
                          Value: "EditorIFrame",
                        },
                        {
                          Name: "Type",
                          Value: "stylesUpdated",
                        },
                      ],
                      NextCommandIdOnSuccess: "2",
                    },
                    {
                      Id: "2",
                      Instruction: {
                        Name: "ClearForm",
                      },
                      Parameters: [{ Name: "Form", Value: "EditFormatsForm" }],
                      NextCommandIdOnSuccess: "3",
                    },
                    {
                      Id: "3",
                      Instruction: {
                        Name: "CloseModalPopup",
                      },
                      Parameters: [
                        { Name: "ModalPopupId", Value: "EditFormatsPopup" },
                        { Name: "CommandResult", Value: "Save" },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            __typename: "Button",
            Name: "CloseModal",
            Id: "4",
            Label: "Close",
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "CloseModalPopup",
                      },
                      Parameters: [{ Name: "ModalPopupId", Value: "EditFormatsPopup" }],
                    },
                  ],
                },
              },
            ],
          },
        ],
        Layout: {
          __typename: "LinearLayout",
          Id: "21",
          // SpacingExtraSmall: "1em",
          DirectionExtraSmall: "column",
        },
      } as graphql.Form,
    ],
  } as graphql.SimpleContainer,
};

export const newPropertyContainer: graphql.SimpleContainer = {
  __typename: "SimpleContainer",
  Id: "sfer34rswsq345/c/1/",
  UniqueName: "Components",
  Items: [
    {
      __typename: "Label",
      Id: "dfs13242sss33234",
      Value: "Property Name: ",
    },
    {
      __typename: "InputField",
      Sequence: 12,
      Name: "PropertyName",
      Id: "0xa412dewq334324dde129",
      Style: {
        minWidth: "250px",
      },
      Disabled: true,
      Bindings: {
        Value: "State.SelectedSampleDataVariable",
      },
    },
    {
      __typename: "Label",
      Id: "1324233234",
      Value: "Data Type: ",
    },
    {
      __typename: "MultiSelect",
      Sequence: 12,
      Name: "Interests",
      Id: "0xa41234324129",
      Style: {
        minWidth: "450px",
      },
      MultiSelectPossibleValues: [
        { Label: "Simple Value (Text, Number,...)", Value: "SimpleValue" },
        { Label: "Single Record (Name-value pairs)", Value: "SingleRecord" },
        { Label: "Multiple Rows (Array of Name-Value Pairs)", Value: "MultipleRows" },
        { Label: "Complex Object", Value: "ComplexObject" },
      ],
      LabelExpression: "Label",
      ValueExpression: "Value",
      Bindings: {
        Value: "State.SampleDataVariableType",
      },
    },
    {
      __typename: "Button",
      Id: "Create-variable",
      Label: "Create New Property",
      Bindings: {
        Disabled: "!State.SelectedSampleDataVariable || !State.SampleDataVariableType",
      },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.SampleData[State.SelectedSampleDataVariable]":
                        "State.SampleDataVariableType === 'SimpleValue' ? '' : (State.SampleDataVariableType === 'SingleRecord' ? { Field1: 'Value1', Field2:'Value2'} : (State.SampleDataVariableType === 'MultipleRows' ? [{ Field1: 'Value1a', Field2:'Value2a'},{ Field1: 'Value1b', Field2:'Value2b'}] : {}))",
                      // "State.SampleDataVariables": "keys(State.SampleData)",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Name: "Transform",
                },
                Parameters: [
                  {
                    Name: "TransformMethod",
                    Value: "JSONToSpreadsheet",
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      TransformMethod:
                        "State.SampleDataVariableType === 'SimpleValue' ? '' : (State.SampleDataVariableType === 'SingleRecord' ? 'JSONToNameValuePair' : (State.SampleDataVariableType === 'MultipleRows' ? 'JSONToSpreadsheet' : ''))",
                      InputData: "State.SampleData[State.SelectedSampleDataVariable]",
                    },
                  },
                  {
                    Name: "DataTransformations",
                    Value: {
                      "State.DataAsGrid": "Event.TransformedData.Rows",
                      "State.GridColumns": "Event.TransformedData.Columns",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "CloseModalPopup",
                },
                Parameters: [{ Name: "ModalPopupId", Value: "NewPropertyDialog" }],
              },
            ],
          },
        },
      ],
    },
  ],
};

export const createNewPropertyDialog: graphql.Dialog = {
  __typename: "Dialog",
  Id: "NewPropertyDialog",
  Title: "Create New Sample Data Property",
  // Style: {
  //   minWidth: "80vw",
  //   minHeight: "80vh",
  // },
  ContentContainer: newPropertyContainer,
};

export const sampleDataEditorContainer: graphql.SimpleContainer = {
  __typename: "SimpleContainer",
  Id: "sfer34rswsq34www5/c/1/2",
  UniqueName: "Components",
  Style: { width: "100%" },
  Items: [
    {
      Typename: "List",
      GridHeight: "700px",
      Id: "/1/2/",
      UniqueName: "ComponentsList",
      Bindings: {
        Rows: "State.SampleDataAsGrid",
        Columns: "State.SampleDataGridColumns",
      },
      SpreadsheetMode: true,
      ReadOnlyEdit: true,
      SpreadsheetModeOptions: {
        Mode: "Excel",
        ShowAddColumnsButton: false,
        ShowAddRowsButton: true,
      },
      Toolbar: {
        ShowExport: true,
        ShowFilter: true,
        Items: [
          {
            __typename: "Button",
            Id: "sdfdssdf4ss34f",
            Label: "Paste from Excel",
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Id: "0x344",
                        Name: "ShowMessage",
                      },
                      Parameters: [
                        { Name: "Message", Value: "This will overwrite all your values.  Continue?" },
                        { Name: "Title", Value: "Paste From Clipboard" },
                        { Name: "ShowOkButton", Value: true },
                        { Name: "ShowCancelButton", Value: true },
                      ],
                      NextCommandIdOnSuccess: "1",
                    },
                    {
                      Id: "1",
                      Instruction: {
                        Name: "PasteFromClipboard",
                      },
                      Parameters: [
                        {
                          Name: "TransformMethod",
                          Value: "ExcelToGrid",
                        },
                        {
                          Name: "Options",
                          Value: {
                            SingleRecord: true,
                          },
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            "State.SampleDataAsGrid": "Event.ClipboardContent.Rows",
                            "State.SampleDataGridColumns": "Event.ClipboardContent.Columns",
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      ListColumns: [
        {
          __typename: "ListColumn",
          Id: "7",
          HeaderName: "",
          Name: "Delete",
          MinWidth: 50,
          Button: {
            __typename: "Button",
            Id: "1",
            StartIcon: { IconName: "Delete" },
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "ShowMessage",
                      },
                      Parameters: [
                        { Name: "Title", Value: "Delete Property" },
                        {
                          Name: "Message",
                          Value: "Are you sure you want to delete this property?",
                        },
                        { Name: "ShowOkButton", Value: true },
                        { Name: "ShowCancelButton", Value: true },
                      ],
                      NextCommandIdOnSuccess: "2",
                    },
                    {
                      Id: "2",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.SampleDataAsGrid":
                              "State.SampleDataAsGrid.filter(function(item) {item.Id !== Event.Id})",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "2a",
                      NextCommandIdOnFailure: "3",
                    },
                    {
                      Id: "2a",
                      Instruction: {
                        Name: "Transform",
                      },
                      Parameters: [
                        {
                          Name: "TransformMethod",
                          Value: "NameValuePairToJSON",
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            InputData: "State.SampleDataAsGrid",
                          },
                        },
                        {
                          Name: "DataTransformations",
                          Value: {
                            "State.SampleData": "Event.TransformedData",
                          },
                        },
                      ],
                    },
                    {
                      Id: "3",
                      Instruction: {
                        Name: "ShowMessage",
                      },
                      Parameters: [
                        { Name: "Message", Value: "Delete failed" },
                        { Name: "ShowOkButton", Value: true },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        } as graphql.ListColumn,
        {
          __typename: "ListColumn",
          Id: "87",
          HeaderName: "",
          Name: "Edit",
          MinWidth: 50,
          Button: {
            __typename: "Button",
            Id: "1sdfast4345",
            StartIcon: { IconName: "Edit" },
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.SelectedSampleDataVariable": "Event.Name",
                            "State.SampleDataVariableType": "getNGObjectType(Event.Value)",
                            "State.SelectedSampleDataValue": "Event.Value",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "1.5",
                    }, //NewPropertyDialog
                    {
                      Id: "1.5",
                      Instruction: {
                        Name: "OpenModalPopup",
                      },
                      Parameters: [
                        { Name: "Title", Value: "Select Property Type" },
                        { Name: "ModalPopupId", Value: "NewPropertyDialog" },
                      ],
                      NextCommandIdOnSuccess: "2",
                      SkipCommand: "State.SelectedSampleDataValue!=null && State.SelectedSampleDataValue!==''",
                    },
                    {
                      Id: "2",
                      Instruction: {
                        Name: "Transform",
                      },
                      Parameters: [
                        {
                          Name: "TransformMethod",
                          Value: "JSONToSpreadsheet",
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            TransformMethod:
                              "State.SampleDataVariableType === 'SingleRecord' ? 'JSONToNameValuePair' : (State.SampleDataVariableType === 'MultipleRows' ? 'JSONToSpreadsheet' : '')",
                            InputData: "State.SampleData[State.SelectedSampleDataVariable]",
                          },
                        },
                        {
                          Name: "DataTransformations",
                          Value: {
                            "State.DataAsGrid": "Event.TransformedData.Rows",
                            "State.GridColumns": "Event.TransformedData.Columns",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "3",
                    },
                    {
                      Id: "3",
                      Instruction: {
                        Name: "OpenModalPopup",
                      },
                      Parameters: [
                        { Name: "Title", Value: "Edit Sample Data" },
                        { Name: "ModalPopupId", Value: "complexSampleDataEditorPopup" },
                      ],
                      //NextCommand: "CommandResult=='Save'?'3':null",
                    },
                  ],
                },
              },
            ],
          },
        } as graphql.ListColumn,
      ],
      EditMode: "cell",
      Actions: [],
    } as graphql.List,
    {
      __typename: "Button",
      Id: "54www3",
      Name: "Save",
      Label: "Save",
      Style: { width: "400px", alignSelf: "center", backgroundColor: "#e6e6e6" },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            //State.DataAsGrid
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "Transform",
                },
                Parameters: [
                  {
                    Name: "TransformMethod",
                    Value: "NameValuePairToJSON",
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      InputData: "State.SampleDataAsGrid",
                    },
                  },
                  {
                    Name: "DataTransformations",
                    Value: {
                      "State.SampleData": "Event.TransformedData",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Name: "CloseModalPopup",
                },
                Parameters: [
                  { Name: "ModalPopupId", Value: "EditSampleDataPopup" },
                  { Name: "CommandResult", Value: "Save" },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
};

export const complexSampleDataEditorContainer: graphql.SimpleContainer = {
  __typename: "SimpleContainer",
  Id: "sfer34r345/c/1/",
  UniqueName: "Components",
  Items: [
    {
      __typename: "SimpleContainer",
      Id: "3421432/c/1/1",
      UniqueName: "Components1",
      Layout: {
        __typename: "FlexLayout",
        Id: "1",
        Direction: "row",
        Style: {
          gap: "10px",
          alignItems: "center",
        },
      },
      Items: [
        {
          __typename: "Label",
          Id: "13242sss33234",
          Value: "Property: ",
        },
        {
          __typename: "Label",
          Sequence: 12,
          Id: "0xa412de334324129",
          Style: {
            minWidth: "250px",
          },
          Bindings: {
            Value: "State.SelectedSampleDataVariable",
          },
        },
        {
          __typename: "Label",
          Id: "1324233234",
          Value: "Data Type: ",
        },
        {
          __typename: "MultiSelect",
          Sequence: 12,
          Name: "Interests",
          DefaultValue: "SingleRecord",
          Id: "0xa41234324129",
          Style: {
            minWidth: "450px",
          },
          MultiSelectPossibleValues: [
            { Label: "Simple Value (Text, Number,...)", Value: "SimpleValue" },
            { Label: "Single Record (Name-value pairs)", Value: "SingleRecord" },
            { Label: "Multiple Rows (Array of Name-Value Pairs)", Value: "MultipleRows" },
            { Label: "Complex Object", Value: "ComplexObject" },
          ],
          LabelExpression: "Label",
          ValueExpression: "Value",
          Disabled: true,
          Bindings: {
            Value: "State.SampleDataVariableType",
          },
        },
      ],
    },
    {
      __typename: "SimpleContainer",
      Id: "t-1",
      Bindings: { Visible: "State.SampleDataVariableType === 'SingleRecord'" },
      Items: [
        {
          __typename: "SimpleContainer",
          Id: "33423412",
          Layout: {
            __typename: "FlexLayout",
            Id: "1ewfdqwe",
            Direction: "row",
          },
          Items: [
            {
              __typename: "Button",
              Id: "sdfdssdf434f",
              Label: "Paste from Excel",
              Actions: [
                {
                  Trigger: "onClick",
                  CommandSet: {
                    FirstCommandId: "1",
                    ExecuteCommandsInParallel: false,
                    Commands: [
                      {
                        Id: "1",
                        Instruction: {
                          Name: "PasteFromClipboard",
                        },
                        Parameters: [
                          {
                            Name: "TransformMethod",
                            Value: "ExcelToGrid",
                          },
                          {
                            Name: "Options",
                            Value: {
                              SingleRecord: true,
                            },
                          },
                          {
                            Name: "Bindings",
                            Value: {
                              "State.DataAsGrid": "Event.ClipboardContent.Rows",
                              "State.GridColumns": "Event.ClipboardContent.Columns",
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
            {
              __typename: "Button",
              Id: "sdfdsf",
              Label: "Transform JSON to Grid",
              Visible: false,
              Actions: [
                {
                  Trigger: "onClick",
                  CommandSet: {
                    FirstCommandId: "1",
                    ExecuteCommandsInParallel: false,
                    Commands: [
                      {
                        Id: "1",
                        Instruction: {
                          Name: "Transform",
                        },
                        Parameters: [
                          {
                            Name: "TransformMethod",
                            Value: "JSONToSpreadsheet",
                          },
                          {
                            Name: "Options",
                            Value: {
                              SingleRecord: false,
                            },
                          },
                          {
                            Name: "Bindings",
                            Value: {
                              InputData: "State.SampleData[State.SelectedSampleDataVariable]",
                            },
                          },
                          {
                            Name: "DataTransformations",
                            Value: {
                              "State.DataAsGrid": "Event.TransformedData.Rows",
                              "State.GridColumns": "Event.TransformedData.Columns",
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },

        {
          Typename: "List",
          GridHeight: "700px",
          Id: "/1/2/",
          UniqueName: "ComponentsList",
          Bindings: {
            Rows: "State.DataAsGrid",
            Columns: "State.GridColumns",
          },
          Toolbar: {
            ShowExport: true,
            ShowFilter: true,
          },
          SpreadsheetMode: true,
          SpreadsheetModeOptions: {
            Mode: "Excel",
            ShowAddColumnsButton: false,
            ShowAddRowsButton: true,
          },
          EditMode: "cell",
          Actions: [],
        } as graphql.List,
      ],
    },
    {
      __typename: "SimpleContainer",
      Id: "t-2",
      Bindings: { Visible: "State.SampleDataVariableType === 'MultipleRows'" },
      Items: [
        {
          __typename: "SimpleContainer",
          Id: "mr-33423412",
          Layout: {
            __typename: "FlexLayout",
            Id: "mr-1ewfdqwe",
            Direction: "row",
          },
          Items: [
            {
              __typename: "Button",
              Id: "sdfdssdf434fssss",
              Label: "Paste from Excel",
              Actions: [
                {
                  Trigger: "onClick",
                  CommandSet: {
                    FirstCommandId: "1",
                    ExecuteCommandsInParallel: false,
                    Commands: [
                      {
                        Id: "1",
                        Instruction: {
                          Name: "PasteFromClipboard",
                        },
                        Parameters: [
                          {
                            Name: "TransformMethod",
                            Value: "ExcelToGrid",
                          },
                          {
                            Name: "Options",
                            Value: {
                              SingleRecord: false,
                            },
                          },
                          {
                            Name: "Bindings",
                            Value: {
                              "State.DataAsGrid": "Event.ClipboardContent.Rows",
                              "State.GridColumns": "Event.ClipboardContent.Columns",
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },

        {
          Typename: "List",
          GridHeight: "700px",
          Id: "/1/3/",
          UniqueName: "ComponentsList",
          Bindings: {
            Rows: "State.DataAsGrid",
            Columns: "State.GridColumns",
          },
          Toolbar: {
            ShowExport: true,
            ShowFilter: true,
          },
          SpreadsheetMode: true,
          SpreadsheetModeOptions: {
            Mode: "Excel",
            ShowAddColumnsButton: true,
            ShowAddRowsButton: true,
          },
          EditMode: "cell",
          Actions: [],
        } as graphql.List,
        {
          __typename: "Button",
          Id: "sdewqfqfdsf",
          Label: "Transform Grid to JSON",
          Visible: false,

          Actions: [
            {
              Trigger: "onClick",
              CommandSet: {
                FirstCommandId: "1",
                ExecuteCommandsInParallel: false,
                Commands: [
                  {
                    Id: "1",
                    Instruction: {
                      Name: "Transform",
                    },
                    Parameters: [
                      {
                        Name: "TransformMethod",
                        Value: "SpreadsheetToJSON",
                      },
                      {
                        Name: "Bindings",
                        Value: {
                          InputData: "State.DataAsGrid",
                        },
                      },
                      {
                        Name: "DataTransformations",
                        Value: {
                          "State.SampleData[State.SelectedSampleDataVariable]": "Event.TransformedData",
                        },
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      __typename: "SimpleContainer",
      Id: "t-3",
      Bindings: {
        Visible: "State.SampleDataVariableType !== 'SingleRecord' && State.SampleDataVariableType !== 'MultipleRows'",
      },
      Items: [
        {
          __typename: "CodeEditor",
          Id: "Value",
          Name: "Value",
          Height: "750px",
          Width: "95vw",
          Bindings: {
            Value: "State.SelectedSampleDataValue",
            Language: "State.SampleDataVariableType === 'SimpleValue'?'text':'json'",
          },
        },
      ],
    },
    {
      __typename: "Button",
      Id: "www3",
      Name: "Add",
      Label: "Save",
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            //State.DataAsGrid
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "Transform",
                },
                Parameters: [
                  {
                    Name: "TransformMethod",
                    Value: "JSONToSpreadsheet",
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      TransformMethod:
                        "State.SampleDataVariableType === 'SingleRecord' ? 'NameValuePairToJSON' : (State.SampleDataVariableType === 'MultipleRows' ? 'SpreadsheetToJSON' : '')",
                      InputData:
                        "State.SampleDataVariableType === 'SingleRecord' || State.SampleDataVariableType === 'MultipleRows'?State.DataAsGrid:State.SelectedSampleDataValue",
                    },
                  },
                  {
                    Name: "DataTransformations",
                    Value: {
                      "State.SampleData[State.SelectedSampleDataVariable]": "Event.TransformedData",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },

              {
                Id: "1",
                Instruction: {
                  Name: "Transform",
                },
                Parameters: [
                  {
                    Name: "TransformMethod",
                    Value: "JSONToNameValuePair",
                  },
                  {
                    Name: "Options",
                    Value: {
                      SingleRecord: true,
                    },
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      InputData: "State.SampleData",
                    },
                  },
                  {
                    Name: "DataTransformations",
                    Value: {
                      "State.SampleDataAsGrid": "Event.TransformedData.Rows",
                      "State.SampleDataGridColumns": "Event.TransformedData.Columns",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },

              {
                Id: "2",
                Instruction: {
                  Name: "CloseModalPopup",
                },
                Parameters: [
                  { Name: "ModalPopupId", Value: "complexSampleDataEditorPopup" },
                  { Name: "CommandResult", Value: "Save" },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
};

export const editActionsContainer: graphql.SimpleContainer = {
  __typename: "SimpleContainer",
  Id: "ActionEditorPopupContainer",
  UniqueName: "ActionEditorPopupContainer",
  Style: { flex: "1" },
  Items: [
    {
      __typename: "ActionEditor",
      Id: "action-editor",
      Name: "Actions",
      Bindings: {
        Value: "State.ActionEditor",
      },
      Actions: [
        {
          Trigger: "onSave",
          CommandSet: {
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.NGForm.NGPropEditor.Actions": "State.ActionEditor.Actions",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Name: "TriggerAction",
                },
                Parameters: [
                  {
                    Name: "Trigger",
                    Value: "onSave",
                  },
                  {
                    Name: "TargetId",
                    Value: "PropertiesEditor/A/1",
                  },
                  {
                    Name: "Form",
                    Value: "NGPropEditor",
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
};

export const complexSampleDataEditorPopup: graphql.Dialog = {
  __typename: "Dialog",
  Id: "complexSampleDataEditorPopup",
  Title: "Edit ComplexSampleData",
  Style: {
    minWidth: "80vw",
    minHeight: "80vh",
  },
  ContentContainer: complexSampleDataEditorContainer,
};

export const editActionsPopup: graphql.Dialog = {
  __typename: "Dialog",
  Id: "EditActionsPopup",
  Title: "Edit Actions",
  ContentStyle: {
    display: "flex",
  },
  Style: {
    minWidth: "90vw",
    minHeight: "90vh",
  },
  ContentContainer: editActionsContainer,
};

export const editSampleData: graphql.Dialog = {
  __typename: "Dialog",
  Id: "EditSampleDataPopup",
  Title: "Edit SampleData",
  ContentStyle: {
    display: "flex",
  },
  Style: {
    minWidth: "80vw",
    minHeight: "80vh",
  },
  ContentContainer: sampleDataEditorContainer,
};

export const inputsEditorContainer: graphql.SimpleContainer = {
  __typename: "SimpleContainer",
  Id: "sf3334www5/c/1/2",
  UniqueName: "Components",
  Items: [
    {
      Typename: "List",
      GridHeight: "700px",
      Id: "/1/2/",
      UniqueName: "ComponentsList",
      Bindings: {
        Rows: "State.SampleDataAsGrid",
        Columns: "State.SampleDataGridColumns",
      },
      SpreadsheetMode: true,
      SpreadsheetModeOptions: {
        Mode: "Excel",
        ShowAddColumnsButton: false,
        ShowAddRowsButton: true,
      },
      Toolbar: {
        ShowExport: true,
        ShowFilter: true,
        Items: [
          {
            __typename: "Button",
            Id: "sdfdssdf4ss34f",
            Label: "Paste from Excel",
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Id: "0x344",
                        Name: "ShowMessage",
                      },
                      Parameters: [
                        { Name: "Message", Value: "This will overwrite all your values.  Continue?" },
                        { Name: "Title", Value: "Paste From Clipboard" },
                        { Name: "ShowOkButton", Value: true },
                        { Name: "ShowCancelButton", Value: true },
                      ],
                      NextCommandIdOnSuccess: "1",
                    },
                    {
                      Id: "1",
                      Instruction: {
                        Name: "PasteFromClipboard",
                      },
                      Parameters: [
                        {
                          Name: "TransformMethod",
                          Value: "ExcelToGrid",
                        },
                        {
                          Name: "Options",
                          Value: {
                            SingleRecord: true,
                          },
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            "State.SampleDataAsGrid": "Event.ClipboardContent.Rows",
                            "State.SampleDataGridColumns": "Event.ClipboardContent.Columns",
                          },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      ListColumns: [
        {
          __typename: "ListColumn",
          Id: "7",
          HeaderName: "",
          Name: "Delete",
          MinWidth: 50,
          Button: {
            __typename: "Button",
            Id: "1",
            StartIcon: { IconName: "Delete" },
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "ShowMessage",
                      },
                      Parameters: [
                        { Name: "Title", Value: "Delete Property" },
                        {
                          Name: "Message",
                          Value: "Are you sure you want to delete this property?",
                        },
                        { Name: "ShowOkButton", Value: true },
                        { Name: "ShowCancelButton", Value: true },
                      ],
                      NextCommandIdOnSuccess: "2",
                    },
                    {
                      Id: "2",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.SampleDataAsGrid":
                              "State.SampleDataAsGrid.filter(function(item) {item.Id !== Event.Id})",
                          },
                        },
                      ],
                      NextCommandIdOnFailure: "3",
                    },
                    {
                      Id: "3",
                      Instruction: {
                        Name: "ShowMessage",
                      },
                      Parameters: [
                        { Name: "Message", Value: "Delete failed" },
                        { Name: "ShowOkButton", Value: true },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        } as graphql.ListColumn,
        {
          __typename: "ListColumn",
          Id: "87",
          HeaderName: "",
          Name: "Edit",
          MinWidth: 50,
          Button: {
            __typename: "Button",
            Id: "1sdfast4345",
            StartIcon: { IconName: "Edit" },
            Actions: [
              {
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "1",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.SelectedSampleDataVariable": "Event.Name",
                            "State.SampleDataVariableType": "getNGObjectType(Event.Value)",
                            "State.SelectedSampleDataValue": "Event.Value",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "1.5",
                    }, //NewPropertyDialog
                    {
                      Id: "1.5",
                      Instruction: {
                        Name: "OpenModalPopup",
                      },
                      Parameters: [
                        { Name: "Title", Value: "Select Property Type" },
                        { Name: "ModalPopupId", Value: "NewPropertyDialog" },
                      ],
                      NextCommandIdOnSuccess: "2",
                      SkipCommand: "State.SelectedSampleDataValue!=null && State.SelectedSampleDataValue!==''",
                    },
                    {
                      Id: "2",
                      Instruction: {
                        Name: "Transform",
                      },
                      Parameters: [
                        {
                          Name: "TransformMethod",
                          Value: "JSONToSpreadsheet",
                        },
                        {
                          Name: "Bindings",
                          Value: {
                            TransformMethod:
                              "State.SampleDataVariableType === 'SingleRecord' ? 'JSONToNameValuePair' : (State.SampleDataVariableType === 'MultipleRows' ? 'JSONToSpreadsheet' : '')",
                            InputData: "State.SampleData[State.SelectedSampleDataVariable]",
                          },
                        },
                        {
                          Name: "DataTransformations",
                          Value: {
                            "State.DataAsGrid": "Event.TransformedData.Rows",
                            "State.GridColumns": "Event.TransformedData.Columns",
                          },
                        },
                      ],
                      NextCommandIdOnSuccess: "3",
                    },
                    {
                      Id: "3",
                      Instruction: {
                        Name: "OpenModalPopup",
                      },
                      Parameters: [
                        { Name: "Title", Value: "Edit Sample Data" },
                        { Name: "ModalPopupId", Value: "complexSampleDataEditorPopup" },
                      ],
                      //NextCommand: "CommandResult=='Save'?'3':null",
                    },
                  ],
                },
              },
            ],
          },
        } as graphql.ListColumn,
      ],
      EditMode: "cell",
      Actions: [],
    } as graphql.List,
    {
      __typename: "Button",
      Id: "54www3",
      Name: "Save",
      Label: "Save",
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            //State.DataAsGrid
            FirstCommandId: "0",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "0",
                Instruction: {
                  Name: "Transform",
                },
                Parameters: [
                  {
                    Name: "TransformMethod",
                    Value: "SpreadsheetToJson",
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      InputData: "State.SampleDataAsGrid",
                    },
                  },
                  {
                    Name: "DataTransformations",
                    Value: {
                      "State.SampleData": "Event.TransformedData",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Name: "CloseModalPopup",
                },
                Parameters: [
                  { Name: "ModalPopupId", Value: "EditSampleDataPopup" },
                  { Name: "CommandResult", Value: "Save" },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
};

export const editInputsPopup: graphql.Dialog = {
  __typename: "Dialog",
  Id: "EditInputsPopup",
  Title: "Edit SampleData",
  Style: {
    minWidth: "80vw",
    minHeight: "80vh",
  },
  ContentContainer: inputsEditorContainer,
};

const setPageCommand = {
  Id: "2",
  Instruction: {
    Name: "SendMessageToChild",
  },
  Parameters: [
    {
      Name: "IFrame",
      Value: "EditorIFrame",
    },
    {
      Name: "Type",
      Value: "setPage",
    },
    {
      Name: "Bindings",
      Value: {
        Page: "State.Page",
      },
    },
    {
      Name: "Actions",
      Value: [
        {
          Trigger: "setPage",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.Page": "Event.Page",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "SendMessageToParent",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      Page: "State.Page",
                      Type: "'onPageUpdate'",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
};

export const headerButtonsByType: { [key: string]: graphql.LayoutItem[] } = {
  Reference: [
    {
      __typename: "Button",
      Id: "ReferenceEditComponent",
      Label: "Edit Component",
      Style: { color: "#323232", width: "100%", maxWidth: "fit-content" },
      StartIcon: {
        IconName: "Edit",
      },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Id: "1",
                  Name: "GoToPage",
                },
                Parameters: [
                  {
                    Name: "Page.Url",
                    Value: "/local/component-editor-gui",
                  },
                  {
                    Name: "URLBindings",
                    Value: {
                      ModelId: "Form.ReferenceId",
                      // IFrameWidth: "'100px'",
                      // EditorMode: "'FormEditorMode'",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    {
      __typename: "Button",
      Id: "ReferenceDetachComponent",
      Style: { color: "#323232", width: "100%", maxWidth: "fit-content" },
      Label: "Detach Component",
      StartIcon: {
        IconName: "LinkOff",
      },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            FirstCommandId: "-1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "-1",
                Instruction: {
                  Id: "0x346d",
                  Name: "ShowMessage",
                },
                Parameters: [
                  { Name: "Title", Value: "Detach Component " },
                  {
                    Name: "Message",
                    Value:
                      "Are you sure you want to detach this component?  From now on any changes made locally will not be reflected in the original component.",
                  },
                  { Name: "ShowOkButton", Value: true },
                  { Name: "ShowCancelButton", Value: true },
                ],
                NextCommandIdOnSuccess: "0",
              },
              {
                Id: "0",
                Instruction: {
                  Name: "CallService",
                },
                Parameters: [{ Name: "ServiceName", Value: "uimetadata.create" }],
                NextCommandIdOnSuccess: "1",
              },
              {
                Id: "1",
                Instruction: {
                  Id: "i_pf_applyButton",
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "findMeta(State.Page,Form.Id)": "State.NewDetachedContainer",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "1.5",
              },
              {
                Id: "1.5",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.PropertiesEditorData": "null",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              setPageCommand,
              // {
              //   Id: "1",
              //   Instruction: {
              //     Name: "SendMessageToChild",
              //   },
              //   Parameters: [
              //     {
              //       Name: "IFrame",
              //       Value: "EditorIFrame",
              //     },
              //     {
              //       Name: "Type",
              //       Value: "replaceSection",
              //     },
              //   ],
              // },
            ],
          },
        },
      ],
    },
  ],
  TabContainer: [
    {
      __typename: "Button",
      Id: "TabContainerAddTabButton",
      Label: "Add Tab",
      StartIcon: {
        IconName: "Add",
      },
      Actions: [
        {
          Trigger: "onClick",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "Form.Items":
                        "FormData.Items.concat([  {  __typename: 'TabItem',Id: uid(), Title: 'New Tab', Items: [{ \
                             __typename: 'SimpleContainer',\
                             Id: uid(),\
                             Items: [],\
                             Layout: {__typename: 'FlexLayout',Direction: 'column',},\
                           }] } ] )",
                    },
                  },
                ],
                NextCommandIdOnSuccess: "2",
              },
              {
                Id: "2",
                Instruction: {
                  Name: "TriggerAction",
                },
                Parameters: [
                  {
                    Name: "Trigger",
                    Value: "onSave",
                  },
                  {
                    Name: "TargetId",
                    Value: "PropertiesEditor/A/1",
                  },
                  {
                    Name: "Event",
                    Value: { Id: 5 },
                  },
                  {
                    Name: "Bindings",
                    Value: {
                      Event: "Form",
                      FormId: "NGPropEditor",
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ],
};

export const contextMenuForState: graphql.ContextMenu = {
  Id: "contextMenuForState",
  __typename: "ContextMenu",
  Content: {
    __typename: "SimpleContainer",
    Layout: {
      __typename: "FlexLayout",
      Classes: ["context-menu"],
    },
    Items: [
      {
        Layout: {
          __typename: "TableLayout",
          Id: "5636trhegertgh",
        },
        Style: {
          width: "100%",
        },
        Typename: "Repeater",
        Id: "g456u657tnyh",
        Variant: "List",
        Bindings: {
          Rows: "State.AllStateVariablesInComponent",
        },

        Content: {
          Id: "weqr29438yt",
          UniqueName: "weqr29438yt",
          __typename: "Form",
          Items: [
            {
              __typename: "Label",
              Name: "Id",
              Id: "5489tfdiuh",
              Visible: false,
            },
            {
              __typename: "Label",
              Name: "Name",
              Id: "dksnfkaw9qr834254353",
            },
            {
              __typename: "Button",
              Id: "sdfdssdfdsd4ss34f",
              //Label: "Paste from Excel",
              StartIcon: { IconName: "ContentCopy" },
              Actions: [
                {
                  Trigger: "onClick",
                  CommandSet: {
                    FirstCommandId: "0",
                    ExecuteCommandsInParallel: false,
                    Commands: [
                      {
                        Id: "0",
                        Instruction: {
                          Name: "CopyToClipboard",
                        },
                        Parameters: [
                          {
                            Name: "Bindings",
                            Value: {
                              ClipboardContent: "Form.Name",
                            },
                          },
                        ],
                        NextCommandIdOnSuccess: "1",
                      },
                      {
                        Id: "1",
                        Instruction: {
                          Name: "CloseContextMenu",
                        },
                        Parameters: [
                          {
                            Name: "ContextMenuId",
                            Value: "contextMenuForState",
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      },
    ],
  },
};
