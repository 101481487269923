import chalk from "chalk";
import { get, isNil, isObject } from "lodash-es";

export type LogLevel = "info" | "warn" | "error" | "debug";
export type LogTag = string;

type LogGroup = {
  tag: LogTag;
  level: LogLevel;
};

export class Logger {
  private static config: Record<string, LogLevel> = {};
  private static levelHierarchy = { debug: 0, info: 1, warn: 2, error: 3 };
  private static isInGroup = false;
  private static logGroup: LogGroup | null = null;

  public static loadConfig(defaultConfig = {}) {
    // Load configuration from local storage
    Logger.config = defaultConfig;

    if (typeof localStorage != "undefined") {
      const storedConfig = localStorage.getItem("NG_LOG");
      Logger.config = storedConfig ? JSON.parse(storedConfig) : defaultConfig;
    }

    log.info("logger", "config", Logger.config);
  }

  private static shouldLog(tag: LogTag, level: LogLevel): boolean {
    if (Logger.isInGroup && Logger.logGroup?.level == "error") return true;

    const configLevel =
      (tag && Logger.config[tag]) || // direct match
      (tag && tag.includes(".") && Logger.config[tag.split(".")[0]]) || // match first part action of action.XYZ
      "warn"; // default

    return Logger.levelHierarchy[level] >= Logger.levelHierarchy[configLevel];
  }

  private static log(tag: LogTag, level: LogLevel, message: string, ...data: any[]) {
    if (Logger.shouldLog(tag, level)) {
      if (Logger.isInGroup) {
        if (level == "error") console.error(`${message}`, ...data);
        else console.log(`${message}`, ...data);
      } else {
        if (level == "error") console.error(Logger.getHeader(tag, level, message), ...data);
        else console.log(Logger.getHeader(tag, level, message), ...data);
      }
    }
  }

  private static colorizeLevel(level: LogLevel): string {
    switch (level) {
      case "info":
        return chalk.bgBlue.black(" INFO ");
      case "error":
        return chalk.bgRed.white(" ERROR ");
      case "warn":
        return chalk.bgYellow.black(" WARN ");
      case "debug":
        return chalk.bgGreen.black(" DEBUG ");
      default:
        return level;
    }
  }

  public static info(tag: LogTag, message: string, ...data: any[]) {
    Logger.log(tag, "info", message, ...data);
  }

  public static warn(tag: LogTag, message: string, ...data: any[]) {
    Logger.log(tag, "warn", message, ...data);
  }

  public static error(tag: LogTag, message: string, ...data: any[]) {
    Logger.log(tag, "error", message, ...data);
  }

  private static timeToMS() {
    const now: Date = new Date();

    // Get hours, minutes, seconds, and milliseconds
    const hours: string = now.getHours().toString().padStart(2, "0");
    const minutes: string = now.getMinutes().toString().padStart(2, "0");
    const seconds: string = now.getSeconds().toString().padStart(2, "0");
    const milliseconds: string = now.getMilliseconds().toString().padStart(3, "0");

    // Format the time string
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  public static debug(tag: LogTag, message: string, ...data: any[]) {
    Logger.log(tag, "debug", Logger.timeToMS() + ": " + message, ...data);
  }

  public static groupInfo(tag: LogTag, message: string, ...data: any[]) {
    Logger.group(tag, "info", message, ...data);
  }

  public static groupError(tag: LogTag, message: string, ...data: any[]) {
    Logger.group(tag, "error", message, ...data);
  }

  public static groupWarn(tag: LogTag, message: string, ...data: any[]) {
    Logger.group(tag, "warn", message, ...data);
  }

  public static groupDebug(tag: LogTag, message: string, ...data: any[]) {
    Logger.group(tag, "debug", Logger.timeToMS() + ": " + message, ...data);
  }

  public static group(tag: LogTag, level: LogLevel, message: string, ...data: any[]) {
    Logger.isInGroup = true;
    Logger.logGroup = { tag, level };
    if (Logger.shouldLog(tag, level)) {
      console.group(Logger.getHeader(tag, level, message), ...data);
    }
  }

  private static getHeader(tag: LogTag, level: LogLevel, message: string) {
    const coloredLevel = Logger.colorizeLevel(level);
    return `${coloredLevel} [${tag}]: ${message} - URL: ${window.location.href}`;
  }

  public static groupEnd() {
    if (Logger.isInGroup && Logger.shouldLog(Logger.logGroup?.tag as LogTag, Logger.logGroup?.level as LogLevel)) {
      console.groupEnd();
    }
    Logger.isInGroup = false;
    Logger.logGroup = null;
  }
}

export const log = Logger;

log.loadConfig({
  interpreter: "warn",
  state: "info",
  action: "info",
  service: "info",
  nats: "info",
  router: "warn",
});
