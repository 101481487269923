/** @jsxImportSource @emotion/react */

import React, { CSSProperties, useRef } from 'react'
import { useSignal, useSignalEffect } from '@preact/signals-react';
import { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { disableNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview";
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { isNil } from 'lodash-es';
import { idleState } from '../library/designer';
import { css } from '@emotion/react';

function getProposedSize(
  startSize: number,
  options: {
    location: DragLocationHistory;
    resizableConfig: IResizableConfig
  }): number {
  if (startSize === 0) return startSize;
  const { location, resizableConfig: { direction = 'right' } } = options;
  if (direction === 'top' || direction === 'bottom') {
    const diffX = location.current.input.clientY - location.initial.input.clientY;
    const proposedHeight = direction === 'bottom' ? startSize + diffX : startSize - diffX;

    return proposedHeight > 1 ? proposedHeight : 1;
  }
  const diffX = location.current.input.clientX - location.initial.input.clientX;
  const proposedWidth = direction === 'right' ? startSize + diffX : startSize - diffX;

  return proposedWidth > 1 ? proposedWidth : 1;
}

interface IResizableConfig {
  startWidth?: string;
  minWidth?: number;
  maxWidth?: number;
  startHeight?: string;
  minHeight?: number;
  maxHeight?: number;

  direction?: 'right' | 'left' | 'top' | 'bottom';
  dragBorderColor?: string;
}

const containerStyles = ({ direction = 'right' }: IResizableConfig) => {
  const map = {
    'right': 'row',
    'left': 'row-reverse',
    'bottom': 'column',
    'top': 'column-reverse'
  }

  return css({
    flexShrink: '0',
    flexGrow: '0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: map[direction],
  })
};

const contentStyles = css({
  flexGrow: '1',
  flexShrink: '1',
  width: 'var(--local-resizing-width, var(--local-initial-width))',
  height: 'var(--local-resizing-height, var(--local-initial-height))',
});

// Quite a large draggable area,
// but the line itself is fairly small
const dividerStyles = ({ direction = 'right' }: IResizableConfig) => css({
  cursor: direction === 'right' || direction === 'left' ? 'ew-resize' : 'ns-resize',
  flexGrow: '0',
  flexShrink: '0',
  position: 'relative',
  background: 'transparent',
  '&::before': (direction === 'right' || direction === 'top') && {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: direction === 'right' ? '4px' : '100%',
    height: direction === 'right' ? '100%' : '-4px',
  },
  '&::after': (direction === 'left' || direction === 'bottom') && {
    content: '""',
    position: 'absolute',
    top: direction === 'bottom' ? '-4px' : 0,
    bottom: 0,
    width: direction === 'left' ? '4px' : '100%',
    height: direction === 'left' ? '100%' : '-4px',
  },
});

// Preventing items getting :hover effects during a drag
const noPointerEventsStyles = css({
  pointerEvents: 'none',
});

const backgroundDrag = ({ dragBorderColor = 'transparent' }: IResizableConfig) => css({
  '&::before': {
    backgroundColor: dragBorderColor,
  },
  '&::after': {
    backgroundColor: dragBorderColor,
  },
});

function convertStringSizeToNumber(
  size: string,
  direction: 'right' | 'left' | 'top' | 'bottom' = 'right',
  parent?: HTMLElement | null
): number {
  const dimensionDirection = direction === 'bottom' || direction === 'top' ? 'vertical' : 'horizontal';
  const referenceElement = parent ?? document.body;
  if (size.includes('%')) {
    const parentSize = dimensionDirection === 'horizontal' ? referenceElement.clientWidth : referenceElement.clientHeight;
    const ratio = Number(size.split('%')[0]) / 100;
    return parentSize * ratio
  }

  if (size.includes('px')) {
    return Number(size.split('px')[0]);
  }

  return 0;
}

export default function useResizable(resizableConfig: IResizableConfig, parent?: HTMLElement | null) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const dividerRef = useRef<HTMLDivElement | null>(null);
  const dragState = useSignal(idleState);
  const initialWidth = useSignal<number>(convertStringSizeToNumber(resizableConfig.startWidth ?? "", resizableConfig.direction, parent));
  const initialheight = useSignal<number>(convertStringSizeToNumber(resizableConfig.startHeight ?? "", resizableConfig.direction, parent));

  useSignalEffect(() => {
    if (isNil(dividerRef.current)) return;
    const divider = dividerRef.current;

    return combine(
      draggable({
        element: divider,
        onGenerateDragPreview: ({ nativeSetDragImage }) => {
          // we will be moving the line to indicate a drag
          // we can disable the native drag preview
          disableNativeDragPreview({ nativeSetDragImage });
          // we don't want any native drop animation for when the user
          // does not drop on a drop target. we want the drag to finish immediately
          preventUnhandled.start();
        },
        onDragStart() {
          dragState.value = { type: 'dragging' };
        },
        onDrag({ location }) {
          if (initialWidth.value !== 0)
            contentRef.current?.style.setProperty(
              '--local-resizing-width',
              `${getProposedSize(initialWidth.value, { location, resizableConfig })}px`,
            );

          if (initialheight.value !== 0)
            contentRef.current?.style.setProperty(
              '--local-resizing-height',
              `${getProposedSize(initialheight.value, { location, resizableConfig })}px`,
            );
        },
        onDrop({ location }) {
          preventUnhandled.stop();
          dragState.value = idleState;

          initialWidth.value = getProposedSize(initialWidth.value, { location, resizableConfig });
          initialheight.value = getProposedSize(initialheight.value, { location, resizableConfig });
          contentRef.current?.style.removeProperty('--local-resizing-width');
          contentRef.current?.style.removeProperty('--local-resizing-height');
        }
      }),
    )
  });

  const Resizable = (reactComponent: React.ReactNode) => {
    if (resizableConfig) {
      return (
        <div css={containerStyles(resizableConfig)}>
          <div
            ref={contentRef}
            css={[contentStyles, dragState.value.type === 'dragging' ? noPointerEventsStyles : undefined]}
            // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
            style={{
              '--local-initial-width': initialWidth.value ? `${initialWidth.value}px` : '100%',
              '--local-initial-height': initialheight.value ? `${initialheight.value}px` : '100%'
            } as CSSProperties}
          >
            {reactComponent}
          </div>
          <div
            css={[
              dividerStyles(resizableConfig),
              // Disabling the cursor on the sidebar line while dragging
              // Otherwise the cursor can flash between resizing and the default cursor repeatedly
              dragState.value.type === 'dragging' ? noPointerEventsStyles : undefined,
              dragState.value.type === 'dragging' ? backgroundDrag(resizableConfig) : undefined,
            ]}
            ref={dividerRef}
          />
        </div>
      )
    }
    else return reactComponent
  }
  return {
    resizable: Resizable
  }
}
