/* eslint-disable @typescript-eslint/ban-types */
//import type CSS from "csstype";
//import type { NGBaseProps } from "~/models/NGBaseProps";
//import { $ } from "@builder.io/qwik";

import { ChangeEventHandler, ReactNode } from "react";
import {
  AiDialog,
  AppBar,
  Button,
  Chart,
  ComponentLibrary,
  Dialog,
  Drawer,
  Feature,
  FlexLayout,
  Form,
  GridStackLayout,
  IApplication,
  IField,
  IFrame,
  Icon,
  Image,
  FileUpload,
  Layout,
  LayoutItem,
  LinearLayout,
  List,
  Menu,
  Page,
  PageTemplate,
  Repeater,
  SimpleContainer,
  TreeList,
  Feed,
  Control,
  CodeEditor,
  Snackbar,
  ResolutionButtons,
  ReviewDialog,
  AudioPlayer,
  ShareDialog,
  TableLayout,
  ProportionalBar,
  Html,
  Avatar,
  PropertiesEditor,
  Slider,
  DateTimePicker,
  DatePicker,
  TabItem,
  FreeFormLayout,
  Switch,
  Checkbox,
  MultiSelect,
  RadioGroup,
  TimePicker,
  Component,
  ContextMenu,
  TabLayout,
  AccordionLayout,
  StyleEditor,
  IconSelector,
  TabContainer,
  Label,
  InputField,
  Reference,
  UndoRedoToolbar,
  ActionEditor,
  BindingEditor,
  ButtonGroup,
  Link,
  VisibleMenu,
  DocumentViewer,
  GridEditor,
} from "../../resolvers-types";
import { Result } from "../models/result";
import { AppDictionary } from "./utils";
import { Signal } from "@preact/signals-react";
import { SxProps, Theme } from "@mui/material";

export type Path = {
  Id: string;
  __typename: string;
  IsForm?: boolean;
  Config: LayoutItem;
  Actions: any;
};

export type RepeaterContext = {
  // Config: Repeater;
  Group?: string;
  Rows?: Signal<any[]>;
  Row?: Signal<any>;
  Index?: number;
};

export type FormContext = {
  Data: Signal<any>;
  Config: any;
};

export type DesignProperties = {
  AllVisible: boolean;
  IgnoreSampleDataInEditor: boolean;
  InteractionMode: boolean;
};

export type RuntimeContext = {
  Path: Path[];
  Repeater?: RepeaterContext;
  Form?: FormContext;
  Handlers?: any;
  InDesignMode?: boolean;
  DesignProps?: DesignProperties;
};

export enum MenuStyle {
  Vertical,
  Horizontal,
}
export interface INGContextMenuProps {
  config: ContextMenu;
  context: RuntimeContext;
}
export interface INGLibraryProps {
  config: ComponentLibrary;
  context: RuntimeContext;
}

export interface INGIFrameProps {
  config: IFrame;
  context: RuntimeContext;
}

export interface IDialogState {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
  onCancel: () => void;
}

export interface IConfigStyleEditor extends StyleEditor {
  Value?: {
    value?: any;
  };
}

export interface INGStyleEditorProps {
  config: IConfigStyleEditor;
  context: RuntimeContext;
}

export interface INGModalPopupEditorProps {
  config: any;
  context: RuntimeContext;
}

export interface INGColorPickerProps {
  selected: Signal<{ [value: string]: string }>;
  property: string;
  label?: string;
  sx?: SxProps<Theme>;
  dataTestId?: string;
}

export interface INGLayoutEditorProps {
  config: IConfigStyleEditor;
  context: RuntimeContext;
}

export interface INGSelectedProps {
  [key: string]: string;
}

export interface INGTextEditorProps {
  selected: Signal<INGSelectedProps>;
}

export interface INGIconSelectorProps {
  config: IconSelector;
  context: RuntimeContext;
}

export interface INGResolutionButtonsProps {
  config: ResolutionButtons;
  context: RuntimeContext;
}

export interface INGUndoRedoToolbarProps {
  config: UndoRedoToolbar;
  context: RuntimeContext;
}

export interface INGDialogProps {
  config: Dialog;
  // dialogId: string;
  context: RuntimeContext;
  //dialogState: IDialogState;
}

export interface INGDrawerProps {
  config: Drawer;
  context: RuntimeContext;
}

export interface INGPageTemplateProps {
  config: PageTemplate;
  context: RuntimeContext;
  children?: ReactNode;
  menu: Menu; // TODO: this might not be a property but rather a runtime "Get".  TBD
}

export interface INGFeatureProps {
  config: Feature;
  context: RuntimeContext;
  handleClose: () => void;
}
export interface INGFeatureSetProps {
  config: Feature;
  context: RuntimeContext;
  handleClose: () => void;
  menuStyle: MenuStyle;
}

export interface INGVisibleMenuProps {
  config: Menu | VisibleMenu;
  context: RuntimeContext;
  handleClose?: () => void;
  menuStyle?: MenuStyle;
}

export interface INGDimensionFieldProps {
  label?: any;
  field: string;
  onValueChange: (e: React.SyntheticEvent<HTMLElement>, value: object, resetMenu?: boolean) => void;
  sizeField?: boolean;
  value: string | number;
  valueType: string;
  styles?: SxProps<Theme>;
  styleSelected?: object;
  fullWidth?: boolean;
}

export interface INGAppBarProps {
  config: AppBar;
  menu: Menu;
  handleClose: () => void;
  context: RuntimeContext;
}
export interface INGBindingEditorProps {
  config: BindingEditor;
  context: RuntimeContext;
}
export interface INGChartProps {
  config: Chart;
  context: RuntimeContext;
}

export interface INGAiDialogProps {
  config: AiDialog;
  context: RuntimeContext;
}

export interface INGTreeListProps {
  config: TreeList;
  context: RuntimeContext;
}

export interface IDragDropObserverSource {
  id: string;
  Template?: any;
}

export interface IMoveItemData {
  DropData: {
    source: {
      data: IDragDropObserverSource;
    };
    location: {
      current: {
        dropTargets: { data: Record<string | symbol, string> }[];
      };
    };
  };
  Page: any;
  Key?: string;
}

export interface INGTreeListItems {
  children?: INGTreeListItems[] | null;
  expanded: boolean;
  icon: { IconName: string };
  id: string;
  index: number;
  item: {
    Classes?: string[];
    Id: string;
    IgnoreLayout: boolean;
    Items: any[];
    Style: { [key: string]: string };
    __typename: string;
  };
  label: string;
  level: number;
  operations: {
    canDelete: boolean;
    canReparent: boolean;
    canReorder: boolean;
    canBeParent: boolean;
    canDrag: false;
  };
  parent: INGTreeListItems | null;
  type: string;
}

export interface INGProportionalBarProps {
  config: ProportionalBar;
  context: RuntimeContext;
}

export interface INGListProps {
  config: List;
  context: RuntimeContext;
}

export interface INGGridEditorProps {
  config: GridEditor;
  context: RuntimeContext;
}

export interface INGGenericControlProps {
  control: Control;
  layoutType: string;
}

export interface INGPropertiesEditor {
  config: PropertiesEditor;
  context: RuntimeContext;
}

export interface INGFormProps {
  config: Form;
  context: RuntimeContext;
}

export interface INGApplicationProps {
  application: IApplication;
  appDictionary: AppDictionary;
}

export interface INGSimpleContainerProps {
  config: SimpleContainer;
  context: RuntimeContext;
  children: React.ReactNode;
}

export interface ITabContainerProps {
  config: TabContainer;
  context: RuntimeContext;
}
export interface INGIconProps {
  config: Icon;
  context: RuntimeContext;
}

export interface INGActionEditorProps {
  config: ActionEditor;
  context: RuntimeContext;
}

export interface INGComponentProps {
  config: Component;
  context: RuntimeContext;
}

export interface INGReferenceProps {
  config: Reference;
  context: RuntimeContext;
}

export interface INGTabItemProps {
  config: TabItem;
  context: RuntimeContext;
}

export interface ITabLayoutProps {
  layoutItem: LayoutItem;
  tabLayout: TabLayout;
  context: RuntimeContext;
}

export interface IAccordionLayoutProps {
  layoutItem: LayoutItem;
  accordionLayout: AccordionLayout;
  context: RuntimeContext;
}

export interface INGIconProps {
  config: Icon;
}

export interface INGHtmlProps {
  config: Html;
  context: RuntimeContext;
}

export interface INGButtonProps {
  config: Button;
  methods?: IButtonMethods;
  context: RuntimeContext;
}

export interface INGDateTimePickerProps {
  config: DateTimePicker;
  context: RuntimeContext;
}

export interface INGDatePickerProps {
  config: DatePicker;
  context: RuntimeContext;
}

export interface INGTimePickerProps {
  config: TimePicker;
  context: RuntimeContext;
}

export interface INGAudioPlayerProps {
  config: AudioPlayer;
  context: RuntimeContext;
}

export interface INGAvatarProps {
  config: Avatar;
  context: RuntimeContext;
}

export interface INGImageProps {
  config: Image;
  context: RuntimeContext;
}

export interface INGTooltipProps {
  config: Tooltip;
}

export interface INGFileUploadProps {
  config: FileUpload;
  context: RuntimeContext;
}

export interface INGRepeaterProps {
  config: Repeater;
  context: RuntimeContext;
}
export interface INGGroupedRepeaterProps {
  config: Repeater;
  context: RuntimeContext;
  local: any;
}
export interface INGCodeEditorProps {
  config: CodeEditor;
  context: RuntimeContext;
  //methods: IFieldMethods;
}

export interface INGSnackbarProps {
  config: Snackbar;
  context: RuntimeContext;
  //methods: IFieldMethods;
}

export interface INGReviewDialogProps {
  config: ReviewDialog;
  context: RuntimeContext;
}

export interface INGShareDialogProps {
  config: ShareDialog;
  context: RuntimeContext;
}
export interface INGControlProps {
  config: IField;
  context: RuntimeContext;
  style: any;
  layoutType: string;
  //methods: IFieldMethods;
}

export interface INGSliderProps {
  config: Slider;
  context: RuntimeContext;
}

export interface INGSwitchProps {
  config: Switch;
  context: RuntimeContext;
}

export interface INGSliderProps {
  config: Slider;
  context: RuntimeContext;
}

export interface INGCheckboxProps {
  config: Checkbox;
  context: RuntimeContext;
}

export interface INGMultiSelectProps {
  config: MultiSelect;
  context: RuntimeContext;
}

export interface INGRadioGroupProps {
  config: RadioGroup;
  context: RuntimeContext;
}

export interface INButtonGroupProps {
  config: ButtonGroup;
  context: RuntimeContext;
}

export interface INGLabelProps {
  config: Label;
  context: RuntimeContext;
}

export interface INGLinkProps {
  config: Link;
  context: RuntimeContext;
}

export interface INGDocumentViewerProps {
  config: DocumentViewer;
  context: RuntimeContext;
}

export interface INGInputFieldProps {
  config: InputField;
  context: RuntimeContext;
}

export interface INGFeedProps {
  config: Feed;
  context: RuntimeContext;

  //methods: IFieldMethods;
}

export interface INGLayoutProps {
  layout: Layout;
  layoutItem: any;
  inDesignMode: boolean;
  context: RuntimeContext;
}

export interface INGPageProps {
  config: Page;
  layout: Layout;
  menu?: Menu;
  context: RuntimeContext;
}

export interface INGFreeFormLayoutProps {
  config: FreeFormLayout;
  context: RuntimeContext;
  inDesignMode: boolean;
  layoutItem: LayoutItem;
}

export interface INGFlexLayoutProps {
  config: FlexLayout;
  context: RuntimeContext;
  inDesignMode: boolean;
  layoutItem: LayoutItem;
  children?: ReactNode;
}

export interface INGTableRowProps {
  item: LayoutItem;
  details?: LayoutItem;
  context: RuntimeContext;
  // tableLayout: TableLayout;
  // inDesignMode: boolean;
  //children: React.ReactNode;
}

export interface INGTableCellProps {
  item: LayoutItem;
  context: RuntimeContext;
  // tableLayout: TableLayout;
  // inDesignMode: boolean;
  //children: React.ReactNode;
}

export interface INGTableLayoutProps {
  config: LayoutItem;
  tableLayout: TableLayout;
  context: RuntimeContext;
  children?: React.ReactNode;
}

export interface INGLayoutItemProps {
  config: LayoutItem;
  context: RuntimeContext;
  layout?: Layout;
  inDesignMode?: boolean;
}
export interface INGLinearLayoutProps {
  layoutItem: LayoutItem;
  linearLayout: LinearLayout;
  appDictionary: AppDictionary;
  inDesignMode: boolean;
}

export interface INGGridStackLayoutProps {
  config: LayoutItem;
  gridStackLayout: GridStackLayout;
  inDesignMode: boolean;
  context: RuntimeContext;
}

/*
export interface IFieldMethods {
    ValueChange(e: { value: any; }): void
    IsValid(value: string): boolean
    GetDefaultValue(): any
}
*/
export interface ISelectFromListMethods extends IFieldMethods {
  GetData(): any;
}

export interface IButtonMethods {
  OnClick: (config: Button, e: { value: any }) => void;
}

export interface IFieldMethods {
  ValueChange: (config: IField, e: { value: any }) => void;
  IsValid: (config: IField, value: string) => Result<any>;
  OnKeyDown: (config: IField, e: { value: any; key: any }) => void;
  //   GetDefaultValue: PropFunction<(config: IField) => any>;
  //   GetStyle: PropFunction<(config: IField) => CSS.Properties>;
}

export type RowGroupPanelShowOptions = "always" | "onlyWhenGrouping" | "never";

export interface GridPrintExportProps {
  fileName?: string;
  hideToolbar?: boolean;
  hideFooter?: boolean;
  includeCheckboxes?: boolean;
  copyStyles?: boolean;
  bodyClassName?: string;
  pageStyle?: string | Function;
  fields?: string[];
  allColumns?: boolean;
}

export interface GridCsvExportProps {
  delimiter?: string;
  fileName?: string;
  utf8WithBom?: boolean;
  includeHeaders?: boolean;
  includeColumnGroupsHeaders?: boolean;
  shouldAppendQuotes?: boolean;
  escapeFormulas?: boolean;
}
/*
export class NgFieldExtensions {

    addAesthetics(props: NGBaseProps){
        const style: CSS.Properties = {
            width : props.aesthetics.Width,
            height :  props.aesthetics.Height
        }

        props.aesthetics.GetStyle = () => { return style;  }
    }

    addCommonExtensions(props: NGBaseProps){
        props.methods.ValueChange= (e: { value: any; }) => { console.log("The value has changed: ",e.value)  }
        props.methods.IsValid= (value: string) => { console.log("IsValid",value);  return value==="Small"; }
        props.methods.GetDefaultValue = () => { return props.config } //
    }

    // These are extension methods that all objects of this type (IField + ISelectFromList) must implement
    addISelectFromListExtensions(props: NGBaseProps){
        props.methods.GetData= () => { return props.config.MultiSelectPossibleValues }
    }
}

export class FieldMethods implements IFieldMethods {

  ValueChange = $((config: IField, e: { value: any }) => {
    console.log("The value has changed: ", e.value);
  });
  IsValid = $((config: IField, value: string) => {
    console.log("IsValid", value);
    return value === "Small";
  });
  GetDefaultValue = $((config: IField) => {
    return config.DefaultValue;
  }); //TODO: ensure this is typed, ie not just a string
  GetStyle = $((config: IField) => {
    const props = {};
    return props;
  });
}
*/
